import React from 'react';

import ResizeableSidebarStyle from './ResizeableSidebar.styles';

type PropsType = {
  onCloseSidebar?: (width: number) => void;
  onChangeWidth: (width: number) => void;
  sidebarRef: React.MutableRefObject<HTMLDivElement | null>;
  border: 'top' | 'bottom' | 'left' | 'right';
  isRightSidebar?: boolean;
};

const ResizeableSidebar: React.FC<React.PropsWithChildren & PropsType> = (props) => {
  const handleSidebarSizing = () => {
    if (!props.sidebarRef.current) {
      return;
    }
    props.onChangeWidth(props.sidebarRef.current?.offsetWidth);
  };

  const handleSidebarSizingEnd = () => {
    if (!props.sidebarRef.current || !props.onCloseSidebar) {
      return;
    }
    props.onCloseSidebar(props.sidebarRef.current?.offsetWidth);
  };

  return (
    <ResizeableSidebarStyle
      border={props.border}
      onUpdate={handleSidebarSizing}
      onEnd={handleSidebarSizingEnd}
      isRightSidebar={props.isRightSidebar}
    >
      {props.children as React.ReactElement}
    </ResizeableSidebarStyle>
  );
};

export default ResizeableSidebar;
