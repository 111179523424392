export enum EnvTypeENUM {
  /** Local development */
  development = 'development',
  /** Dev server */
  dev = 'dev',
  staging = 'staging',
  demo = 'demo',
  production = 'production',
}

export const envType = process.env.REACT_APP_ENV || process.env.NODE_ENV || EnvTypeENUM.development;
export const isDev = envType === EnvTypeENUM.development || envType === EnvTypeENUM.dev;
export const isStage = envType === EnvTypeENUM.staging;
export const isDemo = envType === EnvTypeENUM.demo;
export const isProd = envType === EnvTypeENUM.production;

const config = {
  apiUrl: 'http://api.localhost.com/api/v1',
  socketUrl: 'ws://localhost:4000',
  maxNumberOfAttempts: 10,
  protocol: 'http://',
  domain: 'localhost.com',
  cookieDomain: 'localhost.com',
};

// eslint-disable-next-line default-case
switch (envType) {
  case EnvTypeENUM.dev:
    config.apiUrl = 'https://api.dev.sphera.work/api/v1';
    config.socketUrl = 'wss://api.dev.sphera.work';
    config.protocol = 'https://';
    config.domain = 'dev.sphera.work';
    config.cookieDomain = 'dev.sphera.work';
    break;

  case EnvTypeENUM.staging:
    config.apiUrl = 'https://api.staging.sphera.work/api/v1';
    config.socketUrl = 'wss://api.staging.sphera.work';
    config.protocol = 'https://';
    config.domain = 'staging.sphera.work';
    config.cookieDomain = 'staging.sphera.work';
    break;

  case EnvTypeENUM.demo:
    config.apiUrl = 'https://api.demo.sphera.work/api/v1';
    config.socketUrl = 'wss://api.demo.sphera.work';
    config.protocol = 'https://';
    config.domain = 'demo.sphera.work';
    config.cookieDomain = 'demo.sphera.work';
    break;
}

export default config;
