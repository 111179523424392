const generateSinglePromiseFunction = <T_Response>(
  callback: () => Promise<T_Response>,
): () => Promise<T_Response> => {
  let pendingPromise: Promise<T_Response> | null = null;

  return async () => {
    if (pendingPromise) {
      return pendingPromise;
    }

    pendingPromise = callback();

    const result = await pendingPromise;

    pendingPromise = null;

    return result;
  };
};

export default generateSinglePromiseFunction;
