import { createAsyncThunk } from '@reduxjs/toolkit';
import type { DefaultRootState } from 'react-redux';

import chatWs from 'src/api/ws/chatWs';
import type { IChannel, MessageType } from 'src/types';
import { chatSliceActions } from '../store/Chat.reducer';

const getMyChannels = createAsyncThunk('chatPage-v2/getMyChannels', async () => {
  const { allChannels, channelsMeta } = await chatWs.getMyChannels();

  const channelsObject: Record<string, IChannel> = {};
  const channels: number[] = [];
  const dmChannels: number[] = [];
  const archivedChannels: number[] = [];

  allChannels.channels.forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    channels.push(channel.channelId);
  });

  allChannels.dmChannels.forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    dmChannels.push(channel.channelId);
  });

  allChannels.archivedChannels.forEach((channel) => {
    channelsObject[channel.channelId] = channel;
    archivedChannels.push(channel.channelId);
  });

  return {
    channelsObject,
    channels,
    dmChannels,
    archivedChannels,
    channelsMeta,
  };
});

const markMessageAsRead = createAsyncThunk('chatPage-v2/markMessageAsRead', async (
  data: { message: MessageType; channel: IChannel },
  { getState, dispatch },
) => {
  const store = getState() as DefaultRootState;

  const { channelId } = data.channel;
  const lastViewedMessageTime = data.message.createdAt as string;
  const newChannelMeta = await chatWs.readMessage({
    channelId,
    lastViewedMessageTime,
  });

  dispatch(chatSliceActions.setLastViewedMessageForUser({
    channelId,
    userId: store.main.user!.userId,
    lastViewed: lastViewedMessageTime,
  }));

  return {
    channelId,
    newChannelMeta: newChannelMeta.channelMeta,
    lastViewedMessageTime,
    userId: store.main.user!.userId,
  };
});

export default {
  getMyChannels,
  markMessageAsRead,
};
