import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';

import http, { type ResponseType } from 'src/api/http';

const axiosBaseQuery =
  <T>(): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    AxiosResponse<ResponseType<T>>,
    unknown
  > => async ({ url, method, data, params }) => {
    try {
      const result = await http({
        url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
