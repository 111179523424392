export default {
  server: {
    internalUnexpected: 'errors:server.internal.unexpected',
    validationFailed: 'errors:server.validation.failed',
    validationBodyParsing: 'errors:server.validation.bodyParsing',
  },
  validation: {
    failedFallback: 'errors:validation.failed.fallback',
    unknownUnexpected: 'errors:validation.unknown.unexpected',
    emailInvalid: 'errors:validation.email.invalid',
    emailRequired: 'errors:validation.email.required',
    passwordInvalid: 'errors:validation.password.invalid',
    passwordRequired: 'errors:validation.password.required',
    passwordShort: 'errors:validation.password.short',
    passwordDontMatch: 'errors:validation.password.dontMatch',
    passwordMatch: 'errors:validation.password.match',
    nameInvalid: 'errors:validation.name.invalid',
    nameRequired: 'errors:validation.name.required',
    channelName: 'errors:validation.channelName.duplication',
    confirmationCodeInvalid: 'errors:validation.confirmationCode.invalid',
    maxLength: 'errors:validation.input.maxLength',
    notEmpty: 'errors:validation.input.notEmpty',
  },
  signUp: {
    name: 'errors:auth.signUp.name',
    company: 'errors:auth.signUp.company',
    domainExist: 'errors:auth.signUp.domainExist',
    domainNotValid: 'errors:auth.signUp.domainNotValid',
    domainIsRequired: 'errors:auth.signUp.domainIsRequired',
    email: 'errors:auth.signUp.email',
    phone: 'errors:auth.signUp.phone',
    phoneInvalid: 'errors:auth.phoneNotValid',
    confirmPhone: 'errors:auth.signUp.confirmPhone',
    confirmCodeInvalid: 'errors:auth.signUp.confirmCodeInvalid',
    agree: 'errors:auth.signUp.agree',
  },
  signIn: {
    domainNotExists: 'errors:auth.signIn.domainNotExists',
    userNotExists: 'errors:auth.signIn.userNotExists',
    phoneInvalid: 'errors:auth.phoneNotValid',
  },
  chat: {
    loadingFilesError: 'errors:chat.media.loading',
  },
} as const;
