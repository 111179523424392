export enum SocketEventsENUM {
  connect = 'connect',
  disconnect = 'disconnect',
  error = 'error',
  connectError = 'connect_error',
  customError = 'SEND_ERROR', // 😿😿😿
  userActivityUpdate = 'USER_ACTIVITY_UPDATE',
  getOnlineUsers = 'GET_ONLINE_USERS',
  newUserOnline = 'NEW_USER_ONLINE',
  newUserOffline = 'NEW_USER_OFFLINE',

  chat_getMyChannels = 'CHAT__GET_MY_CHANNELS',
  chat_readMessage = 'CHAT__READ_MESSAGE',
  chat_getChannel = 'CHAT__GET_CHANNEL',
  chat_newMention = 'CHAT__NEW_MENTION',
  chat_removedMention = 'CHAT__REMOVED_MENTION',
  chat_userEntranceStatus = 'CHAT__USER_ENTRANCE_STATUS',

  // Depricated events
  getNewMessage = 'GET_NEW_MESSAGE',
  mentionInChannel = 'MENTION_IN_CHANNEL',
  updatedChannel = 'UPDATED_CHANNEL',
  archivedChannel = 'ARCHIVED_CHANNEL',
  unarchivedChannel = 'UNARCHIVED_CHANNEL',
  addUsersToChannel = 'ADD_USERS_TO_CHANNEL',
  removeUserFromChannel = 'REMOVE_USER_FROM_CHANNEL',
  deletedChannel = 'DELETED_CHANNEL',
  ownerReassigned = 'OWNER_REASSIGNED',
  messageDeleted = 'MESSAGE_DELETED',
  joinRoom = 'JOIN_ROOM',
}

export default SocketEventsENUM;
