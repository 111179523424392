import React from 'react';

import UserMenu from './UserMenu';
import Avatar from 'src/ui/components/Avatar';
import WorkspaceItem from './WorkspaceItem';
import AvatarBlockStyle from './AvatarBlock.styles';

import useMenuAnchor from 'src/utils/hooks/useMenuAnchor';
import { useAppSelector } from 'src/store';

const AvatarBlock: React.FC = () => {
  const {
    anchor,
    isAnchor,
    setEventTargetAsAnchor,
    clearAnchor,
    setAnchor,
  } = useMenuAnchor<HTMLDivElement>();

  const user = useAppSelector(({ main }) => main.user);

  if (!user!.company?.companyId || !user!.company?.subdomen) {
    return null;
  }

  return (
    <AvatarBlockStyle>
      {isAnchor && (
        <UserMenu
          anchor={anchor}
          clearAnchor={clearAnchor}
          isAnchor={isAnchor}
          setAnchor={setAnchor}
        />
      )}
      <div
        className="avatar-group"
        onClick={setEventTargetAsAnchor}
      >
        <WorkspaceItem
          workspaceItem={{
            companyId: user!.company?.companyId,
            subdomen: user!.company?.subdomen,
          }}
          setAnchor={setAnchor}
          size="s"
        />

        <div className="user-avatar">
          <Avatar
            user={user!}
            size="s"
            pointerCursor
            usePreventClick
          />
        </div>
      </div>
    </AvatarBlockStyle>
  );
};

export default AvatarBlock;
