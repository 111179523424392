import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ICompany, IUser } from 'src/types';
import storage from 'src/utils/storage';
import { resetToken } from 'src/api/http';
import authApi from 'src/api/authApi';
import socketConnection from 'src/api/ws/socket';
import helpers from 'src/utils/helpers';
import store from '../store';
import { mainSliceActions } from './mainSlice.reducer';

const authorize = createAsyncThunk(
  'main/authorizeUser',
  async (args, { dispatch }) => {
    const storedAccessToken = storage.authToken.get();
    if (!storedAccessToken) {
      return null;
    }

    const workspacesPromise = dispatch(getWorkspaces()).unwrap().catch(() => ({}));

    const response = await authApi.getMe();
    let user = response.data.payload;
    let subdomain = helpers.getSubdomain();

    if (subdomain !== user.company!.subdomen!) {
      const availableWorkspaces = await workspacesPromise;
      const availableAccount = Object.values(availableWorkspaces).find(
        (item) => item.subdomen === subdomain,
      );

      if (!availableAccount) {
        const pathName = window.location.pathname;
        subdomain = user.company!.subdomen;
        helpers.replaceSubdomain(subdomain, pathName);
      } else {
        const response = await authApi.changeCompany(availableAccount.companyId);
        user = response.data.payload;

        storage.authToken.set(response.data.meta.authToken);
        storage.refreshToken.set(response.data.meta.refreshToken);
        resetToken();

        store.dispatch(mainSliceActions.setSelectedWorkspaces([availableAccount.companyId]));
      }
    }

    socketConnection.connect();

    return user;
  },
);

const getWorkspaces = createAsyncThunk<Record<number, ICompany>, IUser[] | undefined>(
  'main/getWorkspaces',
  async (users) => {
    let accounts: IUser[] = users || [];
    if (!users) {
      const response = await authApi.getMyAccounts();
      accounts = response.data.payload;
    }

    const companyMap = accounts.reduce<Record<number, ICompany>>((acc, user) => {
      if (!user.company) {
        return acc;
      }
      acc[user.company.companyId] = user.company;
      return acc;
    }, {});

    return companyMap;
  },
);

export default {
  authorize,
  getWorkspaces,
};
