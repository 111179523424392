import styled from 'styled-components';
import ArrowTooltip from 'src/ui/pages/Chat/components/ArrowTooltip/ArrowTooltip';

type StyledUserLinkButtonPropsType = {
  isAbsolute?: boolean;
};

const StyledUserLinkButton = styled(ArrowTooltip)<StyledUserLinkButtonPropsType>`
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'static')};
  right: 16px;

  .link-icon path {
    stroke: ${({ theme }) => theme.palette.primary[500]};
  }

  .link-button {
    padding: 3px;
    border-radius: 4px;
    &:hover {
      background: ${({ theme }) => theme.palette.primary[100]};
    }
  }
`;

export default StyledUserLinkButton;
