/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// TODO: Uncomment when will be language change
// import LanguageDetector from 'i18next-browser-languagedetector';

import { LanguageENUM } from 'src/types';
import generalEnNs from './locales/en/general.json';
import homePageEnNs from './locales/en/homePage.json';
import errorsEnNs from './locales/en/errors.json';
import authPagesEnNs from './locales/en/authPages.json';
import hrmEmloyeesNs from './locales/en/hrmEmployees.json';
import chatNs from './locales/en/chat.json';
import termsNs from './locales/en/terms.json';
import sortingNs from './locales/en/sorting.json';
import workspaceNs from './locales/en/workspace.json';

export const defaultNS = 'general';

export const enResource = {
  general: generalEnNs,
  homePage: homePageEnNs,
  errors: errorsEnNs,
  authPages: authPagesEnNs,
  hrmEmployees: hrmEmloyeesNs,
  chat: chatNs,
  terms: termsNs,
  sorting: sortingNs,
  workspace: workspaceNs,
};

export type ResourcesType = typeof enResource;

export const AVAILABLE_LANGUAGES = {
  [LanguageENUM.en]: 'English',
  [LanguageENUM.ru]: 'Русский',
};

const pullLang = (lang: LanguageENUM) => {
  type DeepWriteableType<T> = { [P in keyof T]: DeepWriteableType<T[P]> };

  const resource = {} as DeepWriteableType<ResourcesType>;

  (Object.keys(enResource) as (keyof ResourcesType)[]).forEach((key: keyof ResourcesType) => {
    resource[key] = require(`./locales/${lang}/${key}.json`);
  });

  return resource;
};

const resources: Record<keyof typeof AVAILABLE_LANGUAGES, ResourcesType> = {
  en: enResource,
  ru: pullLang(LanguageENUM.ru),
};

i18n
  .use(initReactI18next)
  // .use(LanguageDetector) // TODO: Uncomment when will be language change
  .init({
    resources,
    fallbackLng: LanguageENUM.ru, // TODO: Replace to en when will be language change
    defaultNS,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

declare module 'react-i18next' {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/naming-convention
  interface Resources extends ResourcesType { }
}

declare module 'react-i18next' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: ResourcesType;
  }
}
