import React from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import Avatar, { type AvatarPropsType } from 'src/ui/components/Avatar';
import WorkspaceItemStyle from './WorkspaceItem.styles';

import type { IWorkspace } from 'src/types';
import storage from 'src/utils/storage';
import { checkIsAxiosError, checkIsValidationError } from 'src/utils/handleValidationError';
import helpers from 'src/utils/helpers';
import { useWorkspacesList } from 'src/utils/hooks/general';
import { t } from 'src/utils/getTranslation';
import authApi from 'src/api/authApi';

const WorkspaceItem: React.FC<{
  workspaceItem: IWorkspace;
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  className?: string;
  size?: AvatarPropsType['size'];
}> = (props) => {
  const { availableWorkspaces } = useWorkspacesList();

  const isActive = helpers.getSubdomain() === props.workspaceItem.subdomen;

  const choosingWorkspace = async () => {
    try {
      if (isActive) {
        return;
      }

      const response = await authApi.changeCompany(props.workspaceItem.companyId);

      storage.authToken.set(response.data.meta.authToken);
      storage.refreshToken.set(response.data.meta.refreshToken);

      helpers.replaceSubdomain(props.workspaceItem.subdomen);
    } catch (err) {
      if (checkIsAxiosError(err) && checkIsValidationError(err)) {
        toast.error(t('errors:server.validation.failed'));
      }
      console.error('src/ui/container/MainSidebar/components/WorkspaceItem.tsx', err);
    }
  };

  return (
    <WorkspaceItemStyle>
      <div
        className={classNames({
          'active-workspace': isActive,
          'cursor-pointer': !isActive,
        })}
      >
        <Avatar
          company={availableWorkspaces[props.workspaceItem.companyId]}
          onClick={choosingWorkspace}
          size={props.size || 'sm'}
        />
      </div>
    </WorkspaceItemStyle>
  );
};

export default WorkspaceItem;
