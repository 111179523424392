import styled from 'styled-components';

import Avatar from '@mui/material/Avatar';

type AvatarPropsType = {
  fontSize: string;
  pointerCursor: boolean;
  background: string;
  className?: string;
};

export default styled(Avatar) <AvatarPropsType>`
  font-size: ${({ fontSize }) => fontSize};
  cursor: ${({ pointerCursor }) => (pointerCursor ? 'pointer' : 'unset')};
  background: ${({ background }) => background};

  .company-icon {
    margin-bottom: -12px;
  }
  border: 1px solid ${({ theme, className }) => (
    Boolean(className) && className === 'channel-member__avatar'
      ? theme.palette.white.A3
      : theme.palette.white.A5
  )};
`;
