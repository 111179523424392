import type { Socket, ManagerOptions, SocketOptions } from 'socket.io-client';
import io from 'socket.io-client';

import generateSinglePromiseFunction from 'src/utils/generateSinglePromiseFunction';
import SocketEventsENUM from './socketEvents';
import refreshToken from '../http/refreshToken';
import { EXPIRED_ERROR_CODE } from '../http/createErrorResponseHandler';
import type { ErrorResponseType } from '../http/http.types';

class BaseSocket {
  readonly socket: Socket;
  private getAuth: () => Record<string, string>;
  private onConnect: () => void;
  private onDisconnect: () => void;
  private onError: (arg: Error) => void;

  constructor(
    connectParams: { uri: string } & Partial<ManagerOptions & SocketOptions>,
    params: {
      getAuth: () => Record<string, string>;
      onConnect?: () => void;
      onDisconnect?: () => void;
      onError?: () => void;
    },
  ) {
    this.socket = io(
      connectParams.uri,
      {
        transports: ['websocket'],
        autoConnect: false,
        auth: {},
        ...connectParams,
      },
    );

    this.socket.on(SocketEventsENUM.error, this.handleError);
    this.socket.on(
      SocketEventsENUM.connectError,
      this.handleConnectionError as unknown as (err: Error) => void,
    );
    this.socket.on(SocketEventsENUM.customError, this.handleCustomError);

    this.getAuth = params.getAuth;
    this.onConnect = params.onConnect || (() => null);
    this.onDisconnect = params.onDisconnect || (() => null);
    this.onError = params.onError || (() => null);

    this.socket.on(SocketEventsENUM.connect, this.onConnect);
    this.socket.on(SocketEventsENUM.disconnect, this.onDisconnect);
    this.socket.on(SocketEventsENUM.error, this.onError);
    this.socket.on(SocketEventsENUM.customError, this.onError);
    this.socket.on(SocketEventsENUM.connectError, this.onError);
  }

  // eslint-disable-next-line class-methods-use-this
  private readonly handleError = (err: Error) => {
    console.error('Socket error:', err);

    this.onError(err);
  };

  readonly connect = async (options?: {
    /** `false` by default */
    shouldThrowError?: boolean;
  }) => {
    if (this.socket.connected) {
      console.error('socket.connect was called with already connected socket');
      return;
    }

    this.socket.auth = this.getAuth();

    await new Promise<void>((res, rej) => {
      const unsubscribe = () => {
        this.socket.off(SocketEventsENUM.connect, handleConnect);
        this.socket.off(SocketEventsENUM.error, handleError);
        this.socket.off(SocketEventsENUM.disconnect, handleDisconnect);
      };

      const handleConnect = () => {
        unsubscribe();
        console.info('Socket is connected');
        res();
      };

      const handleError = (err: Error) => {
        unsubscribe();
        rej(err);
      };

      const handleDisconnect = () => {
        unsubscribe();
        rej(new Error('Socket was disconnected'));
      };

      this.socket.on(SocketEventsENUM.connect, handleConnect);
      this.socket.on(SocketEventsENUM.error, handleError);
      this.socket.on(SocketEventsENUM.disconnect, handleDisconnect);

      this.socket.connect();
    }).catch((err) => {
      if (options?.shouldThrowError) {
        throw err;
      }
    });
  };

  readonly disconnect = async (options?: {
    /** `false` by default */
    shouldThrowError?: boolean;
  }) => {
    if (this.socket.disconnected) {
      console.error('socket.disconnect was called without the connected socket');
      return;
    }

    await new Promise<void>((res, rej) => {
      const unsubscribe = () => {
        this.socket.off(SocketEventsENUM.error, handleError);
        this.socket.off(SocketEventsENUM.disconnect, handleDisconnect);
      };

      const handleError = (err: Error) => {
        unsubscribe();
        rej(err);
      };

      const handleDisconnect = () => {
        unsubscribe();
        console.info('Socket is disconnected');
        res();
      };

      this.socket.on(SocketEventsENUM.error, handleError);
      this.socket.on(SocketEventsENUM.disconnect, handleDisconnect);

      this.socket.disconnect();
    }).catch((err) => {
      if (options?.shouldThrowError) {
        throw err;
      }
    });
  };

  handleConnectionError = async (err: { message: string; data: ErrorResponseType }) => {
    if (err?.data?.code === EXPIRED_ERROR_CODE) {
      await this.refreshToken();
    }

    console.error('Failed to connect to the socket');
  };

  // TODO - We don't need to use any kind of custom error event
  private readonly handleCustomError = async (err: {
    message: string;
    payload: [string, unknown];
  }) => {
    if (err?.message === 'Token expired') {
      const isSuccess = await this.refreshToken();

      if (!isSuccess) {
        console.error('Socket connection error:', err);
        return;
      }

      this.socket.emit(err.payload[0], err.payload[1]);

      return;
    }

    console.error('Custom socket error:', err);
  };

  private readonly refreshToken = generateSinglePromiseFunction(async () => {
    const isSuccess = await refreshToken();

    if (!isSuccess) {
      await this.disconnect();
      return false;
    }

    await this.disconnect();
    const isConnected = await this.connect({ shouldThrowError: true })
      .then(() => true)
      .catch(() => false);

    return isConnected;
  });
}

export default BaseSocket;
