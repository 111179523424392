import styled from 'styled-components';

type StyledUserPopoverContentPropsType = {
  nameWidth: number;
};

const StyledUserPopoverContent = styled.div<StyledUserPopoverContentPropsType>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 4px 4px 8px 4px;
  width: fit-content;

  .user-popover__job-position {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-top: 8px;
    max-width: ${({ nameWidth }) => `${nameWidth}px`};
  }

  .user-popover__name {
    cursor: pointer;
    width: fit-content;
  }

  .user-popover__avatar {
    display: flex;
    align-items: center;
    height: fit-content;
  }
`;

export default StyledUserPopoverContent;
