import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { ChannelType, IChannel, IDiscussion, IMessage, IReaction, IUser, MessageType } from 'src/types';
import { ChatTypesENUM, MessageActionModeENUM } from 'src/types';
import dayjs from 'dayjs';
import _omit from 'lodash/omit';

import type { ChatMediaObjectType, IChannelDelta, IMedia, IMessageText, MediaInfoType, TypingUsersType } from 'src/types/chatTypes';

import type { AppStateType } from 'src/store';
import ChatThunks from './Chat.thunks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import constants from 'src/ui/pages/Chat/constants';

type ResultsType = {
  users: IUser[];
  usersCount: number;
  channels: IChannel[];
  channelsCount: number;
  messages: IMessage[];
  messagesCount: number;
};

type CurrentChatInfoType = {
  id: number;
  name: string;
  type: ChatTypesENUM;
};

interface IChats {
  [key: number]: ChannelType;
}

interface IMessages {
  [key: number]: MessageType;
}

type OverviewType = {
  channels: IChannel[];
};

type GroupMessagesType = {
  [key: string]: {
    date: string;
    messages: MessageType[];
  };
};

const getInitialStore = () => ({
  isShowCreatingChannel: false,
  isShowAddingUsers: false,
  isChannelJustCreating: false,
  isShowNotAvailableDeletingMember: false,
  isShowDeletingMember: false,
  isShowEditingDescription: false,
  isShowEditingChannel: false,
  isShowDeleteChannelConfirm: false,
  isShowLeaveChannelConfirm: false,
  isShowSendingToArchiveConfirm: false,
  isShowArchivalChannelsRestore: false,
  isShowAllArchivalChannelsRestore: false,
  isShowArchivalChannelsDelete: false,
  isShowAddingUsersConfirm: false,
  isShowAddMemberToChannel: false,
  isShowCreatingChat: false,
  isShowRightSideBar: false,
  isActiveSearchInput: false,
  isVisibleSearchResults: false,
  isShowChannelsOverview: false,
  isShowChannelToJoin: false,
  isLoadingChannelsList: false,
  isLoadingChannelsOverview: false,
  isLoadingArchivalChannelsList: false,
  isLoadingChatMessageList: true,
  isLoadingMessagesUp: false,
  isLoadingMessagesDown: false,
  isLoadingChatMedia: false,
  isLoadingDiscussionMedia: false,
  isVisibleArchiveChannels: false,
  isVisibleStartChannelMessage: false,
  isVisibleGoToDownButton: false,
  isAllowChangeLastViewed: false,
  editingChatMessage: null as MessageType | null,
  editingDiscussionMessage: null as MessageType | null,
  replyingChatMessage: null as MessageType | null,
  replyingDiscussionMessage: null as MessageType | null,

  chats: {} as IChats,
  channelOverview: { channels: [] } as OverviewType,
  totalOverviewChannelsCount: 0,
  archivalChats: {} as IChats,
  messages: {} as IMessages,
  channelsIds: [] as number[],
  archivalChannelsIds: [] as number[],
  toActionUsersIds: [] as number[],
  privateChatsIds: [] as number[],
  archivalPrivateChatsIds: [] as number[],
  currentChatInfo: null as CurrentChatInfoType | null,
  currentDiscussion: null as IDiscussion | null,
  currentParentMessageId: null as number | null,
  chatName: null as string | null,
  mediaList: null as File[] | null,
  chatIdsWithMentions: [] as number[],
  currentViewedUserProfile: null as IUser | null,
  visibleMessages: null as MessageType[] | null,
  typingUsers: {} as TypingUsersType,

  currentDeletingMessage: null as IMessage | null,
  currentActiveMessage: null as IMessage | null,
  currentMessageToScrollId: null as number | null,
  currentMessageStopScrollId: null as number | null,
  downloadMessagesTriggerUp: null as number | null,
  downloadMessagesTriggerDown: null as number | null,
  downloadMessagesAnchorUp: null as number | null,
  downloadMessagesAnchorDown: null as number | null,
  messagesDownloadAllowedUp: false,
  messagesDownloadAllowedDown: false,
  chatMediaListObject: {} as ChatMediaObjectType,
  discussionMediaList: null as MediaInfoType | null,
  currentFullImageMediaItem: null as IMedia | null,
  currentMediaForDelete: null as IMedia | null,
  firstUnreadMessageId: null as number | null,
  separatorUnreadMessageId: null as number | null,

  searchResults: {} as ResultsType,
  isSearchResultsLoading: false,
  isUserSearchResultsLoading: false,
  isChannelsSearchResultsLoading: false,
  isMessagesSearchResultsLoading: false,
});

const chatSlice = createSlice({
  name: 'chatPage',
  initialState: getInitialStore,
  reducers: {
    setVisibleMessages: (store, { payload }: PayloadAction<MessageType[] | null>) => {
      store.visibleMessages = payload;
    },
    setIsShowCreatingChannel: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowCreatingChannel = payload;
    },
    setIsShowAddingUsers: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowAddingUsers = payload;
    },
    setIsChannelJustCreating: (store, { payload }: PayloadAction<boolean>) => {
      store.isChannelJustCreating = payload;
    },
    setIsShowNotAvailableDeletingMember: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowNotAvailableDeletingMember = payload;
    },
    setIsShowDeletingMember: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowDeletingMember = payload;
    },
    setIsShowEditingDescription: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowEditingDescription = payload;
    },
    setIsShowEditingChannel: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowEditingChannel = payload;
    },
    setIsShowDeleteChannelConfirm: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowDeleteChannelConfirm = payload;
    },
    setIsShowLeaveChannelConfirm: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowLeaveChannelConfirm = payload;
    },
    setIsShowSendingToArchiveConfirm: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowSendingToArchiveConfirm = payload;
    },
    setIsShowArchivalChannelsRestore: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowArchivalChannelsRestore = payload;
    },
    setIsShowAllArchivalChannelsRestore: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowAllArchivalChannelsRestore = payload;
    },
    setIsShowArchivalChannelsDelete: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowArchivalChannelsDelete = payload;
    },
    setIsShowAddingUsersConfirm: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowAddingUsersConfirm = payload;
    },
    setIsShowAddMemberToChannel: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowAddMemberToChannel = payload;
    },
    setIsShowCreatingChat: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowCreatingChat = payload;
    },
    setIsShowRightSideBar: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowRightSideBar = payload;
    },
    setIsShowChannelsOverview: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowChannelsOverview = payload;
    },
    setIsShowChannelToJoin: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowChannelToJoin = payload;
    },
    setIsVisibleSearchResults: (store, { payload }: PayloadAction<boolean>) => {
      store.isVisibleSearchResults = payload;
    },
    setEditingChatMessage: (store, { payload }: PayloadAction<MessageType | null>) => {
      store.editingChatMessage = payload;
    },
    setEditingDiscussionMessage: (store, { payload }: PayloadAction<MessageType | null>) => {
      store.editingDiscussionMessage = payload;
    },
    setReplyingChatMessage: (store, { payload }: PayloadAction<MessageType | null>) => {
      store.replyingChatMessage = payload;
    },
    setReplyingDiscussionMessage: (store, { payload }: PayloadAction<MessageType | null>) => {
      store.replyingDiscussionMessage = payload;
    },
    setIsActiveSearchInput: (store, { payload }: PayloadAction<boolean>) => {
      store.isActiveSearchInput = payload;
    },
    setIsVisibleArchiveChannels: (store, { payload }: PayloadAction<boolean>) => {
      store.isVisibleArchiveChannels = payload;
    },
    setCurrentChatInfo: (store, { payload }: PayloadAction<CurrentChatInfoType | null>) => {
      store.currentChatInfo = payload;
    },
    setChatName: (store, { payload }: PayloadAction<string | null>) => {
      store.chatName = payload;
    },
    setCurrentViewedUserProfile: (store, { payload }: PayloadAction<IUser | null>) => {
      store.currentViewedUserProfile = payload;
    },
    setChats: (store, { payload }: PayloadAction<IChats>) => {
      store.chats = payload;
      for (const key in store.chats) {
        if (Object.prototype.hasOwnProperty.call(store.chats, key)) {
          const element = store.chats[key];
          if (element.type === ChatTypesENUM.channel) {
            store.channelsIds.push(element.channelId);
          } else {
            store.privateChatsIds.push(element.channelId);
          }
        }
      }
    },
    setArchivalChats: (store, { payload }: PayloadAction<IChats>) => {
      store.archivalChats = payload;
      for (const key in store.archivalChats) {
        if (Object.prototype.hasOwnProperty.call(store.archivalChats, key)) {
          const element = store.archivalChats[key];
          if (element.type === ChatTypesENUM.channel) {
            store.archivalChannelsIds.push(element.channelId);
          } else {
            store.archivalPrivateChatsIds.push(element.channelId);
          }
        }
      }
    },
    clearChatsIds: (store) => {
      store.channelsIds = [];
      store.privateChatsIds = [];
    },
    setChannelOverviewList: (store, { payload }: PayloadAction<IChannel[]>) => {
      store.channelOverview.channels = payload;
    },
    clearArchivalChatsIds: (store) => {
      store.archivalChannelsIds = [];
      store.archivalPrivateChatsIds = [];
    },
    setToActionUsersIds: (store, { payload }: PayloadAction<number[]>) => {
      store.toActionUsersIds = payload;
    },
    addChannel: (store, { payload }: PayloadAction<ChannelType>) => {
      store.chats[payload.channelId] = { ...store.chats[payload.channelId], ...payload };
      store.channelsIds.push(payload.channelId);
      store.channelOverview.channels.push(payload);
    },
    addChannelToArchive: (store, { payload }: PayloadAction<ChannelType>) => {
      store.archivalChats[payload.channelId] = payload;
      store.archivalChannelsIds.push(payload.channelId);
    },
    editChannel: (store, { payload }: PayloadAction<ChannelType>) => {
      store.chats[payload.channelId] = { ...store.chats[payload.channelId], ...payload };
      const index = store.channelOverview.channels.findIndex((channel) => channel.channelId === payload.channelId);
      if (index) {
        store.channelOverview.channels[index] = { ...store.channelOverview.channels[index], ...payload };
      }
    },
    updateOwner: (store, { payload }: PayloadAction<IChannelDelta>) => {
      store.chats[payload.channelId].owner = payload.owner;
    },
    setTotalOverviewChannelsCount: (store, { payload }: PayloadAction<number>) => {
      store.totalOverviewChannelsCount = payload;
    },
    deleteChannel: (store, { payload }: PayloadAction<number>) => {
      store.chats = _omit(store.chats, payload);
      store.channelsIds = store.channelsIds.filter((channelId) => channelId !== payload);
    },
    leaveChannel: (store, { payload }: PayloadAction<{ channelId: number; userId: number }>) => {
      store.channelOverview.channels = store.channelOverview.channels.map((channel) => {
        if (channel.channelId !== payload.channelId) {
          return channel;
        }

        const userToChannelIndex = channel.userToChannels!.findIndex((i) => {
          return i.userId === payload.userId;
        });

        if (userToChannelIndex === -1) {
          return channel;
        }

        const userToChannels = [...channel.userToChannels!];
        userToChannels.splice(userToChannelIndex, 1);

        return {
          ...channel,
          userToChannels,
        };
      });
    },
    deleteArchivalChannel: (store, { payload }: PayloadAction<number>) => {
      store.archivalChats = _omit(store.archivalChats, payload);
      store.archivalChannelsIds = store.archivalChannelsIds.filter(
        (channelId) => channelId !== payload,
      );
    },
    setCurrentDiscussion: (store, { payload }: PayloadAction<IDiscussion | null>) => {
      store.currentDiscussion = payload;
    },
    setCurrentParentMessageId: (store, { payload }: PayloadAction<number | null>) => {
      store.currentParentMessageId = payload;
    },
    setMessagesToChannel: (store, { payload }: PayloadAction<{
      channelId: number;
      messages: MessageType[];
    }>) => {
      payload.messages.forEach((message) => {
        store.messages[message.messageId] = message;
      });
      if (store.isVisibleArchiveChannels) {
        if (!store.archivalChats[payload.channelId].messagesIds) {
          store.archivalChats[payload.channelId].messagesIds = [];
        }
        store.archivalChats[payload.channelId].messagesIds =
          payload.messages.map((message) => message.messageId);
      } else {
        if (!store.chats[payload.channelId].messagesIds) {
          store.chats[payload.channelId].messagesIds = [];
        }
        store.chats[payload.channelId].messagesIds =
          payload.messages.map((message) => message.messageId);
      }
    },
    addMessageToChat: (store, { payload }: PayloadAction<{
      channelId: number;
      message: MessageType;
    }>) => {
      store.messages[payload.message.messageId] = payload.message;
      if (!store.chats[payload.channelId]) {
        return;
      }
      if (!store.chats[payload.channelId].messagesIds) {
        store.chats[payload.channelId].messagesIds = [];
      }
      store.chats[payload.channelId].messagesIds?.push(payload.message.messageId);
      if (!payload.message.referencedMessage) {
        return;
      }
      const referenceId = payload.message.referencedMessage.messageId;
      if (!store.messages[referenceId].messageReferences) {
        store.messages[referenceId].messageReferences = [];
      }
      store.messages[referenceId].messageReferences?.push(payload.message);
    },
    addMessageToDiscussion: (store, { payload }: PayloadAction<MessageType>) => {
      if (store.currentParentMessageId) {
        store.messages[store.currentParentMessageId]
          .childMessagesIds?.push(payload.messageId);
      }
      store.messages[payload.messageId] = payload;
    },
    addMessagesToChat: (store, { payload }: PayloadAction<{
      channelId: number;
      messages: MessageType[];
      anchor: 'up' | 'down';
    }>) => {
      const messages = payload.messages;
      if (payload.anchor === 'down') {
        payload.messages.forEach((message) => {
          store.messages[message.messageId] = message;
          store.chats[payload.channelId].messagesIds?.push(message.messageId);
        });

        const lastIndex = messages.length >= constants.PER_PAGE ? messages.length - 1 : null;
        const anchorDown = lastIndex ? messages[lastIndex].messageId : null;
        const triggerDown = lastIndex
          ? messages[lastIndex - constants.RESERVE_COUNT].messageId
          : null;
        store.downloadMessagesTriggerDown = triggerDown;
        store.downloadMessagesAnchorDown = anchorDown;
        store.currentMessageStopScrollId = triggerDown;
        store.isLoadingMessagesDown = false;
      } else {
        const ids: number[] = [];
        payload.messages.forEach((message) => {
          store.messages[message.messageId] = message;
          ids.push(message.messageId);
        });

        const existIds = store.chats[payload.channelId].messagesIds || [];
        store.chats[payload.channelId].messagesIds = [
          ...ids,
          ...existIds,
        ];
        const triggerUp = messages.length >= constants.PER_PAGE
          ? messages[constants.RESERVE_COUNT].messageId
          : null;
        const anchorUp = messages.length >= constants.PER_PAGE ? messages[0].messageId : null;
        store.downloadMessagesTriggerUp = triggerUp;
        store.downloadMessagesAnchorUp = anchorUp;
        store.currentMessageStopScrollId = triggerUp;
        store.isLoadingMessagesUp = false;
        store.isVisibleGoToDownButton = true;
      }
    },
    editMessage: (store, { payload }: PayloadAction<{
      messageId: number;
      value: string;
    }>) => {
      const messageText = store.messages[payload.messageId].messageText?.[0];
      if (messageText) {
        messageText.text = payload.value;
        store.messages[payload.messageId].updatedAt = new Date();
        store.messages[payload.messageId].isEdited = true;
      }
    },
    updateUploadedMedia: (store, { payload }: PayloadAction<{
      messageId: number;
      value: {
        uploadedMedia: IMedia; uploadedMediaInfoId: string;
      };
    }>) => {
      const uploadedMediaItemsInMessage = store.messages[payload.messageId]?.media;

      if (!uploadedMediaItemsInMessage) {
        return;
      }
      uploadedMediaItemsInMessage.push(payload.value.uploadedMedia);
    },
    deleteMessage: (store, { payload }: PayloadAction<{
      deletedAt: Date | string;
      messageId: number;
      channelId: number;
    }>) => {
      if (!store.messages[payload.messageId]) {
        return;
      }
      if (!store.messages[payload.messageId].messageReferences?.length) {
        const messages = store.chats[payload.channelId].messagesIds;
        const referencedMessageId = store.messages[payload.messageId].referencedMessage?.messageId;
        store.chats[payload.channelId].messagesIds = messages?.filter((id) => {
          const condition = id !== payload.messageId;
          if (!referencedMessageId || !store.messages[referencedMessageId].deletedAt) {
            return condition;
          }
          return condition && id !== referencedMessageId;
        });
        if (referencedMessageId) {
          store.messages = _omit(store.messages, referencedMessageId);
        }

        store.messages = _omit(store.messages, payload.messageId);
      } else {
        store.messages[payload.messageId].deletedAt = payload.deletedAt;
      }
    },
    setChatMediaObject: (store, { payload }: PayloadAction<{ mediaInfo: MediaInfoType | null; channelId: number }>) => {
      store.chatMediaListObject[payload.channelId] = payload.mediaInfo;
    },
    // TODO Update handle media items for discussion
    setDiscussionMediaList: (store, { payload }: PayloadAction<MediaInfoType | null>) => {
      store.discussionMediaList = payload;
    },
    setIsLoadingChatMedia: (store, { payload }: PayloadAction<boolean>) => {
      store.isLoadingChatMedia = payload;
    },
    setIsLoadingDiscussionMedia: (store, { payload }: PayloadAction<boolean>) => {
      store.isLoadingDiscussionMedia = payload;
    },
    changeChatMediaLoadingInfo: (store, { payload }: PayloadAction<{
      id: string;
      messageId: number | null;
      uploaded: number;
      total: number;
      channelId: number;
    }>) => {
      if (payload.messageId === null) {
        const channelMedia = store.chatMediaListObject[payload.channelId];
        if (!channelMedia || !channelMedia[payload.id]) {
          return;
        }

        const mediaToUpdate = channelMedia[payload.id];
        mediaToUpdate.uploaded = payload.uploaded;
        mediaToUpdate.totalSize = payload.total;
        if (payload.uploaded === payload.total) {
          mediaToUpdate.isUploaded = true;
        }
      } else {
        const messageToUpdate = store.messages[payload.messageId];
        if (!messageToUpdate || !messageToUpdate.mediaLocal) {
          return;
        }
        const mediaToUpdate = messageToUpdate.mediaLocal
          .find((item) => item.mediaInfoId === payload.id);

        if (!mediaToUpdate) {
          return;
        }

        mediaToUpdate.uploaded = payload.uploaded;
        mediaToUpdate.totalSize = payload.total;
        if (payload.uploaded === payload.total) {
          mediaToUpdate.isUploaded = true;
          messageToUpdate.mediaLocal = messageToUpdate.mediaLocal
            .filter((item) => item.mediaInfoId !== payload.id);
        }
      }
    },
    changeDiscussionMediaLoadingInfo: (store, { payload }: PayloadAction<{
      id: string;
      uploaded: number;
      total: number;
    }>) => {
      if (!store.discussionMediaList) {
        return;
      }
      store.discussionMediaList[payload.id].uploaded = payload.uploaded;
      store.discussionMediaList[payload.id].totalSize = payload.total;
    },
    addChatMediaItem: (store, { payload }: PayloadAction<{
      uploadedMedia: IMedia;
      uploadedMediaInfoId: string;
      channelId: number;
    }>) => {
      const channelMedia = store.chatMediaListObject[payload.channelId];
      if (!channelMedia) {
        return;
      }
      channelMedia[payload.uploadedMediaInfoId].mediaItem = payload.uploadedMedia;
    },
    addDiscussionMediaItem: (store, { payload }: PayloadAction<{
      uploadedMedia: IMedia;
      uploadedMediaInfoId: string;
      channelId: number;
    }>) => {
      if (!store.discussionMediaList) {
        return;
      }
      store.discussionMediaList[payload.uploadedMediaInfoId].mediaItem = payload.uploadedMedia;
    },
    addMessageReaction: (store, { payload }: PayloadAction<{
      messageId: number;
      reaction: IReaction;
    }>) => {
      if (!store.messages[payload.messageId].messageToReactions) {
        store.messages[payload.messageId].messageToReactions = [];
      }
      store.messages[payload.messageId].messageToReactions?.push(payload.reaction);
    },
    removeMessageReaction: (store, { payload }: PayloadAction<{
      messageId: number;
      messageReactionId?: number;
    }>) => {
      store.messages[payload.messageId].messageToReactions =
        store.messages[payload.messageId]
          .messageToReactions?.filter((reaction) => {
            return reaction.messageReactionId !== payload.messageReactionId;
          });
    },
    deleteChatIdWithMentions: (store, { payload }: PayloadAction<number>) => {
      store.chatIdsWithMentions = store.chatIdsWithMentions.filter((id) => id === payload);
    },
    setCurrentActiveMessage: (store, { payload }: PayloadAction<IMessage | null>) => {
      store.currentActiveMessage = payload;
    },
    setIsLoadingChatMessageList: (store, { payload }: PayloadAction<boolean>) => {
      store.isLoadingChatMessageList = payload;
    },
    changeMessageStatus: (store, { payload }: PayloadAction<{
      messageId: number;
      hasBeenRead: boolean;
      hasDiscussion?: boolean;
      messageText?: IMessageText[];
      newMessageId?: number;
      createdAt?: string;
      channelId: number;
    }>) => {
      if (payload.newMessageId) {
        store.messages[payload.messageId].hasBeenRead = payload.hasBeenRead;
        if (payload.hasDiscussion) {
          store.messages[payload.messageId].hasDiscussion = payload.hasDiscussion;
        }
        if (payload.createdAt) {
          store.messages[payload.messageId].createdAt = payload.createdAt;
        }
        if (payload.messageText) {
          store.messages[payload.messageId].messageText = payload.messageText;
        }
        store.messages[payload.messageId] = _omit(store.messages[payload.messageId], 'isSending');
        store.messages[payload.newMessageId] = store.messages[payload.messageId];
        store.messages[payload.newMessageId].messageId = payload.newMessageId;
        store.messages = _omit(store.messages, payload.messageId);
        if (!store.chats[payload.channelId].messagesIds?.length) {
          store.chats[payload.channelId].messagesIds = [payload.newMessageId];
        }
        store.chats[payload.channelId].messagesIds?.push(payload.newMessageId);
        const newMessageIds = store.chats[payload.channelId].messagesIds?.filter((id) => {
          return id !== payload.messageId;
        });
        store.chats[payload.channelId].messagesIds = newMessageIds;
      } else {
        store.messages[payload.messageId].hasBeenRead = payload.hasBeenRead;
      }
    },
    setMessagesDownloadTriggerUp: (store, { payload }: PayloadAction<number | null>) => {
      store.downloadMessagesTriggerUp = payload;
    },
    setMessagesDownloadTriggerDown: (store, { payload }: PayloadAction<number | null>) => {
      store.downloadMessagesTriggerDown = payload;
    },
    setMessagesDownloadAnchorUp: (store, { payload }: PayloadAction<number | null>) => {
      store.downloadMessagesAnchorUp = payload;
    },
    setMessagesDownloadAnchorDown: (store, { payload }: PayloadAction<number | null>) => {
      store.downloadMessagesAnchorDown = payload;
    },
    setMessagesDownloadAllowedUp: (store, { payload }: PayloadAction<boolean>) => {
      store.messagesDownloadAllowedUp = payload;
    },
    setMessagesDownloadAllowedDown: (store, { payload }: PayloadAction<boolean>) => {
      store.messagesDownloadAllowedDown = payload;
    },
    setCurrentMessageToScrollId: (store, { payload }: PayloadAction<number | null>) => {
      store.currentMessageToScrollId = payload;
    },
    setCurrentMessageStopScrollId: (store, { payload }: PayloadAction<number | null>) => {
      store.currentMessageStopScrollId = payload;
    },
    setIsLoadingMessagesUp: (store, { payload }: PayloadAction<boolean>) => {
      store.isLoadingMessagesUp = payload;
    },
    setIsLoadingMessagesDown: (store, { payload }: PayloadAction<boolean>) => {
      store.isLoadingMessagesDown = payload;
    },
    setMessagesLoadingStatusesIsNull: (store) => {
      store.messagesDownloadAllowedUp = false;
      store.messagesDownloadAllowedDown = false;
      store.downloadMessagesAnchorUp = null;
      store.downloadMessagesAnchorDown = null;
      store.downloadMessagesTriggerUp = null;
      store.downloadMessagesTriggerDown = null;
      store.currentMessageToScrollId = null;
    },
    setIsErrorMessage: (store, { payload }: PayloadAction<{ messageId: number }>) => {
      store.messages[payload.messageId].isError = true;
      store.messages[payload.messageId].isSending = false;
    },
    setIsSendingMessage: (store, { payload }: PayloadAction<{ messageId: number }>) => {
      store.messages[payload.messageId].isError = false;
      store.messages[payload.messageId].isSending = true;
    },
    setIsSendingMessageSuccess: (store, { payload }: PayloadAction<{ messageId: number }>) => {
      if (!store.messages[payload.messageId]) {
        return;
      }
      store.messages[payload.messageId].isError = false;
      store.messages[payload.messageId].isSending = false;
    },
    removeErrorMessage: (store, { payload }: PayloadAction<{ messageId: number }>) => {
      const channelId = store.currentChatInfo?.id;

      if (channelId) {
        const updatedMessagesIds = store.chats[channelId].messagesIds?.filter((id) => {
          return id !== payload.messageId;
        });
        store.chats[channelId].messagesIds = updatedMessagesIds;
        store.messages = _omit(store.messages, payload.messageId);
      }
    },
    setMessage: (store, { payload }: PayloadAction<MessageType>) => {
      store.messages[payload.messageId] = { ...store.messages[payload.messageId], ...payload };
    },
    setPinnedToChat: (store, { payload }: PayloadAction<{
      channelId: number;
      pinnedMessages: IMessage[];
    }>) => {
      if (store.isVisibleArchiveChannels) {
        if (store.archivalChats[payload.channelId]) {
          store.archivalChats[payload.channelId].pinnedMessages = payload.pinnedMessages;
        }
      } else if (store.chats[payload.channelId]) {
        store.chats[payload.channelId].pinnedMessages = payload.pinnedMessages;
      }
    },
    togglePinMessage: (store, { payload }: PayloadAction<{
      channelId: number;
      messageId: number;
      isPinned: boolean;
    }>) => {
      const message = store.messages[payload.messageId];
      if (message) {
        message.isPinned = payload.isPinned;
      }
      if (payload.isPinned) {
        message.isPinned = true;
        if (!store.chats[payload.channelId].pinnedMessages?.length) {
          store.chats[payload.channelId].pinnedMessages = [message];
        } else {
          store.chats[payload.channelId].pinnedMessages?.push(message);
        }
      } else {
        const list = store.chats[payload.channelId].pinnedMessages?.filter((message) => {
          return message.messageId !== payload.messageId;
        });
        store.chats[payload.channelId].pinnedMessages = list;
      }
    },
    updatePinnedMessages: (store, { payload }: PayloadAction<{
      channelId: number;
    }>) => {
      const pinnedMessages = store.chats[payload.channelId]?.pinnedMessages;
      if (!pinnedMessages?.length) {
        return;
      }

      const messageIds = store.chats[payload.channelId].messagesIds;
      const pinnedMessageIds = pinnedMessages.map((item) => item.messageId);

      if (messageIds?.length) {
        pinnedMessageIds.forEach((pinnedId) => {
          const wasPinnedMessageRemoved = !messageIds.includes(pinnedId);
          if (!wasPinnedMessageRemoved) {
            return;
          }
          const pinnedMessageToDeleteIndex = pinnedMessages.findIndex((item) => {
            return item.messageId === pinnedId;
          });
          pinnedMessages.splice(pinnedMessageToDeleteIndex, 1);
        });
      }

      const updatedMessages = Object.values(store.messages)
        .filter((item) => {
          return pinnedMessageIds.includes(item.messageId);
        });

      updatedMessages.forEach(((item) => {
        const updatedMessageId = item.messageId;
        const updatedPinnedMessageIndex = pinnedMessages.findIndex((item) => {
          return item.messageId === updatedMessageId;
        });

        const wasPinnedMessageUpdated = updatedPinnedMessageIndex !== -1;
        if (!wasPinnedMessageUpdated) {
          return;
        }
        pinnedMessages[updatedPinnedMessageIndex] = {
          ...pinnedMessages[updatedPinnedMessageIndex], ...item,
        };
      }));
    },
    setCurrentMediaForDelete: (store, { payload }: PayloadAction<IMedia | null>) => {
      store.currentMediaForDelete = payload;
    },
    deleteMediaItem: (store, { payload }: PayloadAction<{
      messageId: number;
      mediaItemId: number;
      mode?: MessageActionModeENUM;
    }>) => {
      const message = store.messages[payload.messageId];
      if (!message.media) {
        return;
      }
      const index = message.media.findIndex((item) => item.mediaItemId === payload.mediaItemId);
      const deletedAt = new Date(Date.now());
      message.media[index].deletedAt = deletedAt;

      if (!store.editingChatMessage) {
        return;
      }
      const isEditingMode = payload.mode === MessageActionModeENUM.editing &&
        store.editingChatMessage &&
        store.editingChatMessage?.messageId === payload.messageId;

      if (isEditingMode) {
        const editingMessage = store.editingChatMessage;
        const mediaItems = editingMessage.media?.length ? editingMessage.media : null;

        if (!mediaItems) {
          return;
        }

        const index = mediaItems.findIndex((item) => item.mediaItemId === payload.mediaItemId);
        mediaItems[index].deletedAt = deletedAt;
      }
    },
    incrementUnreadMessagesCount: (store, { payload }: PayloadAction<{ chatId: number }>) => {
      const count = store.chats[payload.chatId].unreadMessagesCount;
      const newCount = count ? (+count + 1) : 1;
      store.chats[payload.chatId].unreadMessagesCount = newCount;
    },
    incrementMentionsCount: (store, { payload }: PayloadAction<{ chatId: number }>) => {
      const count = store.chats[payload.chatId].mentionsCount;
      const newCount = count ? (+count + 1) : 1;
      store.chats[payload.chatId].mentionsCount = newCount;
    },
    decrementUnreadMessagesCount: (store, { payload }: PayloadAction<{ chatId: number }>) => {
      const count = store.chats[payload.chatId].unreadMessagesCount;
      const newCount = count ? (+count - 1) : 0;
      store.chats[payload.chatId].unreadMessagesCount = newCount;
    },
    decrementMentionsCount: (store, { payload }: PayloadAction<{ chatId: number }>) => {
      const count = store.chats[payload.chatId].mentionsCount;
      const newCount = count ? (+count - 1) : 0;
      store.chats[payload.chatId].mentionsCount = newCount;
    },
    setCurrentDeletingMessage: (store, { payload }: PayloadAction<IMessage | null>) => {
      store.currentDeletingMessage = payload;
    },
    setCurrentFullImageMediaItem: (store, { payload }: PayloadAction<IMedia | null>) => {
      store.currentFullImageMediaItem = payload;
    },
    setFirstUnreadMessageId: (store, { payload }: PayloadAction<number | null>) => {
      store.firstUnreadMessageId = payload;
    },
    setSeparatorUnreadMessageId: (store, { payload }: PayloadAction<number | null>) => {
      store.separatorUnreadMessageId = payload;
    },
    setLastViewedMessageForUser: (store, { payload }: PayloadAction<{
      channelId: number;
      userId: number;
      lastViewed: string | Date;
    }>) => {
      if (!store.chats[payload.channelId]) {
        return;
      }
      const userToChannels = store.chats[payload.channelId].userToChannels;
      if (!userToChannels || !userToChannels.length) {
        return;
      }
      const index = userToChannels.findIndex((userToChannel) => {
        return userToChannel.user?.userId === payload.userId;
      });
      if (index === -1) {
        return;
      }
      userToChannels[index].lastViewedMessageTime = payload.lastViewed;
    },
    setIsVisibleStartChannelMessage: (store, { payload }: PayloadAction<boolean>) => {
      store.isVisibleStartChannelMessage = payload;
    },
    setIsAllowChangeLastViewed: (store, { payload }: PayloadAction<boolean>) => {
      store.isAllowChangeLastViewed = payload;
    },
    setIsVisibleGoToDownButton: (store, { payload }: PayloadAction<boolean>) => {
      store.isVisibleGoToDownButton = payload;
    },
    setTypingUsers: (store, { payload }: PayloadAction<TypingUsersType>) => {
      store.typingUsers = payload;
    },
    deleteFromTypingUsers: (store, { payload }: PayloadAction<number>) => {
      if (store.typingUsers[payload]) {
        store.typingUsers = _omit(store.typingUsers, payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ChatThunks.getAllChannels.pending, (store) => {
      store.isLoadingChannelsList = true;
    });
    builder.addCase(ChatThunks.getAllChannels.fulfilled, (store, { payload }) => {
      store.isLoadingChannelsList = false;
      if (payload?.channel) {
        payload.channel.forEach((channel) => {
          store.chats[channel.channelId] = channel;
          store.channelsIds.push(channel.channelId);
        });
      }
    });
    builder.addCase(ChatThunks.getAllChannels.rejected, (store) => {
      store.isLoadingChannelsList = false;
      store.chats = {};
      store.channelsIds = [];
      store.privateChatsIds = [];
    });

    builder.addCase(ChatThunks.getArchivalChannels.pending, (store) => {
      store.isLoadingArchivalChannelsList = true;
    });
    builder.addCase(ChatThunks.getArchivalChannels.fulfilled, (store, { payload }) => {
      store.isLoadingArchivalChannelsList = false;
      if (payload) {
        payload.forEach((channel) => {
          store.archivalChats[channel.channelId] = channel;
          store.archivalChannelsIds.push(channel.channelId);
        });
      }
    });
    builder.addCase(ChatThunks.getArchivalChannels.rejected, (store) => {
      store.isLoadingArchivalChannelsList = false;
      store.archivalChats = [];
      store.archivalChannelsIds = [];
      store.archivalPrivateChatsIds = [];
    });

    builder.addCase(ChatThunks.getSearchResults.pending, (store) => {
      store.isSearchResultsLoading = true;
    });
    builder.addCase(ChatThunks.getSearchResults.fulfilled, (store, { payload }) => {
      store.isSearchResultsLoading = false;
      store.searchResults = payload;
    });
    builder.addCase(ChatThunks.getSearchResults.rejected, (store) => {
      store.isSearchResultsLoading = false;
      // error
    });

    builder.addCase(ChatThunks.getUsersSearchResults.pending, (store) => {
      store.isUserSearchResultsLoading = true;
    });
    builder.addCase(ChatThunks.getUsersSearchResults.fulfilled, (store, { payload }) => {
      store.isUserSearchResultsLoading = false;
      store.searchResults.users = payload.users;
      store.searchResults.usersCount = payload.usersCount;
    });
    builder.addCase(ChatThunks.getUsersSearchResults.rejected, (store) => {
      store.isUserSearchResultsLoading = false;
      // error
    });

    builder.addCase(ChatThunks.getChannelsSearchResults.pending, (store) => {
      store.isChannelsSearchResultsLoading = true;
    });
    builder.addCase(ChatThunks.getChannelsSearchResults.fulfilled, (store, { payload }) => {
      store.isChannelsSearchResultsLoading = false;
      store.searchResults.channels = payload.channels;
      store.searchResults.channelsCount = payload.channelsCount;
    });
    builder.addCase(ChatThunks.getChannelsSearchResults.rejected, (store) => {
      store.isChannelsSearchResultsLoading = false;
      // error
    });

    builder.addCase(ChatThunks.getMessagesSearchResults.pending, (store) => {
      store.isMessagesSearchResultsLoading = true;
    });
    builder.addCase(ChatThunks.getMessagesSearchResults.fulfilled, (store, { payload }) => {
      store.isMessagesSearchResultsLoading = false;
      store.searchResults.messages = payload.messages;
      store.searchResults.messagesCount = payload.messagesCount;
    });
    builder.addCase(ChatThunks.getMessagesSearchResults.rejected, (store) => {
      store.isMessagesSearchResultsLoading = false;
      // error
    });

    builder.addCase(ChatThunks.getChannelsToOverview.pending, (store) => {
      store.isLoadingChannelsOverview = true;
    });
    builder.addCase(ChatThunks.getChannelsToOverview.fulfilled, (store, { payload }) => {
      store.isLoadingChannelsOverview = false;
      store.channelOverview.channels = payload.payload;
      store.totalOverviewChannelsCount = payload.meta.totalRecords;
    });
    builder.addCase(ChatThunks.getChannelsToOverview.rejected, (store) => {
      store.isLoadingChannelsOverview = false;
      // error
    });
  },
});

export const selectChannel = createSelector(
  ({ chatPage }: AppStateType) => chatPage.chats,
  ({ chatPage }: AppStateType) => chatPage.currentChatInfo?.id,
  ({ chatPage }: AppStateType) => chatPage.archivalChats,
  ({ chatPage }: AppStateType) => chatPage.isVisibleArchiveChannels,
  (chats, chatId, archivalChats, isVisibleArchiveChannels) => {
    if (chatId) {
      if (isVisibleArchiveChannels) {
        return archivalChats[chatId];
      }
      return chats[chatId];
    }
  },
);

export const selectParentMessage = createSelector(
  ({ chatPage }: AppStateType) => chatPage.messages,
  ({ chatPage }: AppStateType) => chatPage.currentParentMessageId,
  (messages, parentMessageId) => {
    if (parentMessageId) {
      return messages[parentMessageId];
    }
  },
);

export const selectChannels = createSelector(
  ({ chatPage }: AppStateType) => chatPage.chats,
  ({ chatPage }: AppStateType) => chatPage.channelsIds,
  (chats, channelIds) => {
    return Object.keys(chats)?.length ? channelIds.map((id) => chats[id]) : [];
  },
);

export const selectArchivalChannels = createSelector(
  ({ chatPage }: AppStateType) => chatPage.archivalChats,
  ({ chatPage }: AppStateType) => chatPage.archivalChannelsIds,
  (archivalChats, archivalChannelsIds) => {
    return archivalChannelsIds.map((id) => archivalChats[id]);
  },
);

export const selectPrivateChats = createSelector(
  ({ chatPage }: AppStateType) => chatPage.chats,
  ({ chatPage }: AppStateType) => chatPage.privateChatsIds,
  (chats, privateChatsIds) => {
    return privateChatsIds.map((id) => chats[id]);
  },
);

export const selectDiscussionMessages = createSelector(
  ({ chatPage }: AppStateType) => chatPage.currentParentMessageId,
  ({ chatPage }: AppStateType) => chatPage.messages,
  (parentMessage, messages) => {
    if (parentMessage) {
      const ids = messages[parentMessage].childMessagesIds;
      return ids?.map((id) => messages[id]);
    }
  },
);

export const selectChannelMedia = createSelector(
  ({ chatPage }: AppStateType) => chatPage.currentChatInfo?.id,
  ({ chatPage }: AppStateType) => chatPage.chatMediaListObject,
  (currentChatId, chatMediaList) => {
    if (currentChatId) {
      return chatMediaList[currentChatId];
    }
    return null;
  },
);

export const selectMessages = createSelector(
  ({ main }: AppStateType) => main.user,
  ({ chatPage }: AppStateType) => chatPage.currentChatInfo?.id,
  ({ chatPage }: AppStateType) => chatPage.messages,
  ({ chatPage }: AppStateType) => chatPage.chats,
  ({ chatPage }: AppStateType) => chatPage.archivalChats,
  ({ chatPage }: AppStateType) => chatPage.isVisibleArchiveChannels,
  ({ main }: AppStateType) => main.language,
  (user, channelId, messages, chats, archivalChats, isVisibleArchiveChannels, language) => {
    if (
      !channelId ||
      (isVisibleArchiveChannels && !archivalChats[channelId]) ||
      (!isVisibleArchiveChannels && !chats[channelId])
    ) {
      return;
    }

    const ids = isVisibleArchiveChannels
      ? archivalChats[channelId].messagesIds
      : chats[channelId].messagesIds;

    const channel = chats[channelId] || archivalChats[channelId];
    const lastViewedMessageTime = channel.userToChannels?.find(({ userId }) => {
      return userId === user?.userId;
    })?.lastViewedMessageTime;

    let firstUnreadMessage: MessageType | null = null;
    const messageList = ids
      ?.map((id) => {
        const message = messages[id];

        if (!firstUnreadMessage && lastViewedMessageTime && !message.isSending) {
          const isMyMessage = message.authorId === user?.userId;
          const isUnread = new Date(message.createdAt) > new Date(lastViewedMessageTime);

          if (!isMyMessage && isUnread) {
            firstUnreadMessage = message;
          }
        }

        return message;
      })
      .sort((a, b) => {
        if (Boolean(a.isSending) > Boolean(b.isSending)) {
          return 1;
        }
        if (Boolean(a.isSending) < Boolean(b.isSending)) {
          return -1;
        }
        if (Boolean(a.isError) > Boolean(b.isError)) {
          return 1;
        }
        if (Boolean(a.isError) < Boolean(b.isError)) {
          return -1;
        }
        return 0;
      });

    const reversedList = messageList?.reverse();

    const groupedByDateMessages = reversedList?.reduce<GroupMessagesType>((acc, message) => {
      const date = dayjs(message.createdAt).locale(language).format('YYYY-MM-DD 00:00');
      if (!acc[date]) {
        acc[date] = { date, messages: [message] };
      } else {
        acc[date].messages.push(message);
      }
      return acc;
    }, {});

    return {
      groupedByDateMessages,
      groupedByDateMessagesKeys: Object.keys(groupedByDateMessages || {}),
      reversedList,
      firstUnreadMessageId: (firstUnreadMessage as unknown as (MessageType | null))?.messageId,
    };
  },
);

export const chatSliceActions = chatSlice.actions;

export default chatSlice.reducer;
