import { createAsyncThunk } from '@reduxjs/toolkit';

import type { GetUsersListParamsType } from 'src/api/userApi';
import userApi from 'src/api/userApi';

const getUsersList = createAsyncThunk(
  'hrmEmployees/getUsersList',
  async (params: GetUsersListParamsType) => {
    const response = await userApi.getList(params);

    return {
      users: response.data.payload,
      pagesInfo: response.data.meta,
    };
  },
);

export default { getUsersList };
