import styled from 'styled-components';

import Paper from '@mui/material/Paper';

export default styled(Paper)`
  position: relative;

  .table-loading-backdrop {
    position: absolute;
  }

  .table-loader {
    position: absolute;
  }
`;
