import styled from 'styled-components';

import Badge from '@mui/material/Badge';

type StatusBadgeProp = {
  isOnline: boolean;
  customColor?: {
    success: string;
    secondary: string;
  }
  size: 'xs' | 'sm';
  withStroke?: boolean;
}

export default styled(Badge)<StatusBadgeProp>`
  .MuiBadge-badge {
    background-color: ${({ isOnline, customColor }) => isOnline ? customColor?.success : customColor?.secondary};
    width: ${({ size }) => size === 'xs' ? '8px' : '16px'};
    height: ${({ size }) => size === 'xs' ? '8px' : '16px'};
    border-radius: 50%;
    border: ${({ theme, withStroke }) => withStroke ? `1px solid ${theme.palette.white.A5}` : 'none'};
  }
`;
