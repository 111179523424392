import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  color: ${({ theme }) => theme.palette.secondary.light};
  background-color: ${({ theme }) => theme.palette.background.default};  
  padding: 21px 20px 20px 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  .area-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    color: ${({ theme }) => theme.palette.text.tertiary};
  }
  .company-icon {
    margin-bottom: -12px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .company-title {
    color: ${({ theme }) => theme.palette.text.tertiary};
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }
  .domain-title {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
  .workers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .invite {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.primary[500]};
  }

  .menu-item-link {
    width: fit-content;
  }
`;
