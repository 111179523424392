import styled from 'styled-components';

import { DragSizing } from 'react-drag-sizing';

export type DragSizingPropsType = {
  isRightSidebar?: boolean;
};

export default styled(DragSizing)<DragSizingPropsType>`
  width: inherit;
  height: 100%;
  min-width: ${({ isRightSidebar }) => (isRightSidebar ? '250px' : '100px')};
  max-width: 420px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.palette.white.A1};
`;
