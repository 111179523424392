export const TOKEN_EXPIRED_ERROR = 'Token expired';

export default {
  SEND_REGULAR_MESSAGE: 'SEND_REGULAR_MESSAGE',
  SEND_ERROR: 'SEND_ERROR',
  SEND_THREAD_MESSAGE: 'SEND_THREAD_MESSAGE',
  GET_CHANNEL_MESSAGES: 'GET_CHANNEL_MESSAGES',
  SEND_ANSWER_TO_MESSAGE: 'SEND_ANSWER_TO_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  MESSAGE_DELETED: 'MESSAGE_DELETED',
  GET_NEW_MESSAGE: 'GET_NEW_MESSAGE',
  GET_NEW_THREAD_MESSAGE: 'GET_NEW_THREAD_MESSAGE',
  ADD_USERS_TO_CHANNEL: 'ADD_USERS_TO_CHANNEL',
  GET_USERS_LIST_IN_CHANNEL: 'GET_USERS_LIST_IN_CHANNEL',
  GET_UPDATED_MESSAGE: 'GET_UPDATED_MESSAGE',
  ADD_REACTION: 'ADD_REACTION',
  DELETE_REACTION: 'DELETE_REACTION',
  GET_REACTION: 'GET_REACTION',
  REACTION_DELETED: 'REACTION_DELETED',
  JOIN_ROOM: 'JOIN_ROOM',
  MENTION_IN_CHANNEL: 'MENTION_IN_CHANNEL',
  REMOVE_USER_FROM_CHANNEL: 'REMOVE_USER_FROM_CHANNEL',
  UPDATE_READ_STATUS: 'UPDATE_READ_STATUS',
  SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR',
  NEW_ACTION_MESSAGE: 'NEW_ACTION_MESSAGE',
  GET_UPDATED_PINNED_MESSAGES: 'GET_UPDATED_PINNED_MESSAGES',
  USER_TYPING: 'USER_TYPING',
  USERS_ONLINE: 'USERS_ONLINE',
  TIMEOUT: 10000,
};
