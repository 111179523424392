import { createApi } from '@reduxjs/toolkit/query/react';

import type { ResponseType } from 'src/api/http';
import baseQuery from './baseQuery';
import type { IJobPosition } from 'src/types';

const path = 'job-positions';

export const jobPositionsQuery = createApi({
  reducerPath: 'jobPositions',
  baseQuery: baseQuery<IJobPosition[]>(),
  endpoints: (builder) => ({
    getJobPositions: builder.query<ResponseType<IJobPosition[]>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
    createJobPosition: builder.mutation<ResponseType<IJobPosition>, { name: string }>({
      query: (data) => ({
        url: path,
        method: 'POST',
        data,
      }),
    }),
    updateJobPosition:
      builder.mutation<ResponseType<IJobPosition>, { name: string; jobPositionId: number }>({
        query: (data) => ({
          url: `${path}/${data.jobPositionId}`,
          method: 'PATCH',
          data: { name: data.name },
        }),
      }),
    deleteJobPosition: builder.mutation<ResponseType, number>({
      query: (id) => ({
        url: `${path}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetJobPositionsQuery,
  useCreateJobPositionMutation,
  useUpdateJobPositionMutation,
  useDeleteJobPositionMutation,
} = jobPositionsQuery;
