import React from 'react';

import IconButton from '@mui/material/IconButton';
import Loader from 'src/ui/components/Loader';
import Toastify from 'src/ui/containers/Toastify';
import MainSidebar from 'src/ui/containers/MainSidebar/MainSidebar';
import HrmSidebar from 'src/ui/containers/HrmSidebar/HrmSidebar';
import Router from 'src/ui/containers/Router';

import { useAppDispatch, useAppSelector } from 'src/store';
import { mainSliceThunks } from 'src/store/mainSlice';
import images from './ui/assets/images';
import BrowserNotifications from './utils/browserNotifications';
import './api/socket';
import useUsersOnlineUpdate from './utils/hooks/useUsersOnlineUpdate';

BrowserNotifications.requestPermissions();

const App: React.FC = () => {
  useUsersOnlineUpdate();
  const isAuthChecked = useAppSelector(({ main }) => main.isAuthChecked);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(mainSliceThunks.authorize());
  }, [dispatch]);

  if (!isAuthChecked) {
    return <Loader isFixed />;
  }

  return (
    <>
      <Toastify
        autoClose={3000}
        draggable
        newestOnTop
        hideProgressBar
        closeButton={({ closeToast }) => (
          <IconButton onClick={closeToast}>
            <images.CloseIcon />
          </IconButton>
        )}
      />

      <MainSidebar />

      <HrmSidebar />

      <Router />
    </>
  );
};

export default App;
