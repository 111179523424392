import React from 'react';

import IconButton from '@mui/material/IconButton';
import StyledUserLinkButton from 'src/ui/components/UserLinkButton/UserLinkButton.styles';
import { ReactComponent as LinkIcon } from 'src/ui/pages/Chat/assets/icons/link.svg';
import { ROUTES } from 'src/utils/constants';
import helpers from 'src/utils/helpers';
import { t } from 'src/utils/getTranslation';

type UserLinkButtonPropsType = {
  isAbsolute?: boolean;
  userId?: number;
};

const UserLinkButton: React.FC<UserLinkButtonPropsType> = (props) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.stopPropagation();
    if (!props.userId) {
      return;
    }
    const linkToProfile = helpers.getAbsoluteUrl({ path: ROUTES.profile.createPath(props.userId) });
    helpers.writeToClipboard(
      linkToProfile,
      {
        showToast: true,
        successMessage: t('general:copyResult.success'),
        errorMessage: t('general:copyResult.error'),
      },
    );
  };

  return (
    <StyledUserLinkButton
      title={t('chat:userProfile.copyLink')}
      isAbsolute={props.isAbsolute}
      placement={props.isAbsolute ? 'top-end' : 'top'}
    >
      <IconButton className="link-button" onClick={handleClick}>
        <LinkIcon
          className="link-icon"
          width={props.isAbsolute ? '24px' : '20px'}
          height={props.isAbsolute ? '24px' : '20px'}
        />
      </IconButton>
    </StyledUserLinkButton>
  );
};

export default UserLinkButton;
