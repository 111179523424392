import socket from './socket';
import { SocketEventsENUM } from './socketEvents';

const getOnlineUsers = async () => {
  const response = await socket.emit<number[]>(SocketEventsENUM.getOnlineUsers, null);

  return response.payload;
};

const subscribeOnNewOnlineUsers = (callback: (userId: number) => void) => {
  return socket.addEventHandler<{ userId: number }>(SocketEventsENUM.newUserOnline, (response) => {
    callback(response.payload.userId);
  });
};

const subscribeOnNewOfflineUsers = (callback: (userId: number) => void) => {
  return socket.addEventHandler<{ userId: number }>(SocketEventsENUM.newUserOffline, (response) => {
    callback(response.payload.userId);
  });
};

export default {
  getOnlineUsers,
  subscribeOnNewOnlineUsers,
  subscribeOnNewOfflineUsers,
};
