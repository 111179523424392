import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _uniq from 'lodash/uniq';
import _omit from 'lodash/omit';

import chatThunksV2 from './chatThunksV2';
import { ChatTypesENUM, type ChannelMetaDataType, type IChannel, type IMessage } from 'src/types';
import helpers from 'src/utils/helpers';

const chatSliceV2 = createSlice({
  name: 'chatPage-v2',
  initialState: () => ({
    channelsObject: {} as Record<string, IChannel>,
    channels: [] as number[],
    dmChannels: [] as number[],
    archivedChannels: [] as number[],
    channelsMeta: {} as Record<string, ChannelMetaDataType>,
    isChannelsLoading: false,
    getMyChannelsError: null as SerializedError | null,
  }),
  reducers: {
    handleNewChannel: (store, { payload }: PayloadAction<{
      channel: IChannel;
      meta?: ChannelMetaDataType;
    }>) => {
      const { channel } = payload;
      store.channelsObject[channel.channelId] = channel;
      store.channelsMeta[channel.channelId] = payload.meta || {
        channelId: channel.channelId,
        unreadMessagesCount: 0,
        isContainsMention: false,
      };
      if (channel.isArchived) {
        store.archivedChannels.push(channel.channelId);
        store.archivedChannels = _uniq(store.archivedChannels);
      } else if (channel.type === ChatTypesENUM.channel) {
        store.channels.push(channel.channelId);
        store.channels = _uniq(store.channels);
      } else {
        store.dmChannels.push(channel.channelId);
        store.dmChannels = _uniq(store.dmChannels);
      }
    },
    handleNewMessage: (store, { payload }: PayloadAction<{
      chatId: number;
      isMyMessage: boolean;
      message: IMessage;
    }>) => {
      if (!payload.isMyMessage) {
        store.channelsMeta[payload.chatId].unreadMessagesCount += 1;

        new Audio('/assets/sound/icq.mp3').play(); // Just for fun 🙂
      }
    },
    handleNewMention: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      store.channelsMeta[payload.channelId].isContainsMention = true;
    },
    handleChannelUpdate: (
      store,
      { payload }: PayloadAction<{
        channel: { channelId: number } & Partial<IChannel>;
        channelMeta?: ChannelMetaDataType;
      }>,
    ) => {
      const currentValue = store.channelsObject[payload.channel.channelId];

      if (typeof payload.channel.isArchived === 'boolean' && currentValue?.isArchived !== payload.channel.isArchived) {
        if (payload.channel.isArchived) {
          store.archivedChannels.push(payload.channel.channelId);
          store.channels = helpers.removeFromArray(store.channels, payload.channel.channelId);
        } else {
          store.channelsObject[payload.channel.channelId] = {
            ...store.channelsObject[payload.channel.channelId],
            ...payload.channel,
          };
          store.channels.push(payload.channel.channelId);
          store.archivedChannels = helpers.removeFromArray(
            store.archivedChannels,
            payload.channel.channelId,
          );
        }
      }

      store.channelsObject[payload.channel.channelId] = {
        ...currentValue,
        ...payload.channel,
      };

      if (payload.channelMeta) {
        store.channelsMeta[payload.channel.channelId] = {
          ...store.channelsMeta[payload.channel.channelId],
          ...payload.channelMeta,
        };
      }
    },
    handleChannelDelete: (store, { payload }: PayloadAction<{ channelId: number }>) => {
      const channel = store.channelsObject[payload.channelId];
      if (channel.isArchived) {
        store.archivedChannels = helpers.removeFromArray(store.archivedChannels, payload.channelId);
      } else {
        store.channels = helpers.removeFromArray(store.channels, payload.channelId);
      }
      store.channelsObject = _omit(store.channelsObject, payload.channelId);
    },
    handleChannelUsersUpdate: (store, { payload }: PayloadAction<{
      channel: IChannel;
      withMe: boolean;
    }>) => {
      if (payload.withMe) {
        store.channelsObject[payload.channel.channelId].userToChannels = payload.channel.userToChannels; // eslint-disable-line max-len
        return;
      }

      store.channelsObject = _omit(store.channelsObject, payload.channel.channelId);
      if (payload.channel.isArchived) {
        store.archivedChannels = helpers.removeFromArray(store.archivedChannels, payload.channel.channelId); // eslint-disable-line max-len
        return;
      }
      store.channels = helpers.removeFromArray(store.channels, payload.channel.channelId);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chatThunksV2.getMyChannels.fulfilled, (store, { payload }) => {
      store.channelsObject = payload.channelsObject;
      store.channels = payload.channels;
      store.dmChannels = payload.dmChannels;
      store.archivedChannels = payload.archivedChannels;
      store.channelsMeta = payload.channelsMeta;
      store.isChannelsLoading = false;
    });
    builder.addCase(chatThunksV2.getMyChannels.pending, (store) => {
      store.isChannelsLoading = true;
    });
    builder.addCase(chatThunksV2.getMyChannels.rejected, (store, { error }) => {
      console.error('Failed to get my channels', error);

      store.getMyChannelsError = error;
      store.isChannelsLoading = false;
    });

    builder.addCase(chatThunksV2.markMessageAsRead.fulfilled, (store, { payload }) => {
      store.channelsMeta[payload.channelId] = payload.newChannelMeta;
      const userToChannelIndex = store.channelsObject[payload.channelId].userToChannels!
        .findIndex((userToChannel) => userToChannel.userId === payload.userId);

      store.channelsObject[payload.channelId].userToChannels![userToChannelIndex].lastViewedMessageTime = payload.lastViewedMessageTime; // eslint-disable-line max-len
    });
  },
});

export const chatSliceV2Actions = chatSliceV2.actions;

export default chatSliceV2;
