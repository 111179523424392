import themes from 'src/ui/styles/themes';
import { useAppSelector } from 'src/store';

const useTheme = () => {
  const themeKey = useAppSelector(({ main }) => main.theme);

  return themes[themeKey];
};

export default useTheme;
