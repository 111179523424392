import constants from '../../../../constants';

export const trim = (value: string) => {
  const withoutBreak = value.replace(/(<p><br><\/p>){2,}/g, '<p><br></p>');
  const withoutSpace = withoutBreak.replace(/<p>\s+<\/p>/g, '');
  const withoutUselessListItems = withoutSpace.replace(/<li><br><\/li>|<li>\s+<\/li>/g, '');
  const withoutUselessLists = withoutUselessListItems.replace(/<ol><\/ol>|<ul><\/ul>/g, '');
  const withoutInnerSpace = withoutUselessLists.replace(/(<[^>]+>)(\s{0,}.+\s{0,})(<\/[^>]>)/gm, (...args) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fullMatch, openTag, content, closeTag] = args;

    return `${openTag}${content.trim()}${closeTag}`;
  });
  return withoutInnerSpace.replace(/^(<p><br><\/p>)|(<p><br><\/p>)$/g, '');
};

export const isSupportedImage = (imageType: string) => {
  return constants.SUPPORTED_IMAGE_TYPE.some((imgType: string) => imageType.includes(imgType));
};
