import i18n from 'i18next';
import type { TFunction } from 'react-i18next';
import { type ResourcesType } from 'src/i18n';

export const t = i18n.t as TFunction<(keyof ResourcesType)[], undefined>;

const getTranslation = () => {
  return {
    t,
    language: i18n.language,
  };
};

export default getTranslation;
