import React from 'react';

import MenuItemStyle from './MenuItem.styles';

const MenuItem: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  onClick?: () => void;
  menuPunktIcon: string;
  menuPunktTitle: string;
}> = (props) => {
  const handler = () => {
    if (!props.onClick) {
      return props.setAnchor(null);
    }

    props.onClick();
  };

  return (
    <MenuItemStyle onClick={handler}>
      <img src={props.menuPunktIcon} alt={props.menuPunktTitle} />
      <p className="item-title">
        {props.menuPunktTitle}
      </p>
    </MenuItemStyle >
  );
};

export default MenuItem;
