import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StatusBadge from 'src/legacy/ui/components/StatusBadge';
import UserLinkButton from 'src/ui/components/UserLinkButton/UserLinkButton';
import StyledUserPopoverContent from 'src/ui/components/UserPopover/UserPopoverContent.styles';

import useTheme from 'src/utils/hooks/useTheme';
import type { IUser } from 'src/types';
import useElementWidth from 'src/utils/hooks/useElementWidth';
import { useUserOnlineStatus } from 'src/utils/hooks/general';

type UserPopoverContentPropsType = {
  user?: IUser;
  avatar: JSX.Element;
  handleClick: React.MouseEventHandler<HTMLDivElement>;
};

const MAX_LASTNAME_LENGTH = 8;

const UserPopoverContent: React.FC<UserPopoverContentPropsType> = (props) => {
  const { t } = useTranslation('chat');
  const theme = useTheme();
  const fullname = props.user?.fullName?.split(' ').reverse().join(' ');
  const lastNameLength = (props.user && props.user.lastName?.length) || 0;
  const nameRef = React.useRef(null);
  const nameWidth = useElementWidth(nameRef);
  const isOnline = useUserOnlineStatus(props.user!.userId);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.stopPropagation();
  };

  return (
    <StyledUserPopoverContent nameWidth={nameWidth}>
      <Box display="flex" gap="20px">
        <StatusBadge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          isOnline={isOnline}
          customColor={{
            success: theme.palette.badge.success,
            secondary: theme.palette.badge.secondary,
          }}
          size="sm"
          withStroke
          className="user-popover__avatar"
        >
          {props.avatar}
        </StatusBadge>
        <Box display="flex" alignItems="start" gap="10px">
          <Box>
            <div
              onClick={props.handleClick}
              className="user-popover__name"
              ref={nameRef}
            >
              {lastNameLength < MAX_LASTNAME_LENGTH ? (
                <Typography variant="h2">
                  {fullname}
                </Typography>
              ) : (
                <>
                  <Typography variant="h2">
                    {props.user?.lastName}
                  </Typography>
                  <Typography variant="h2">
                    {props.user?.firstName}
                  </Typography>
                </>
              )}
              <Typography variant="h2">
                {props.user?.patronymic}
              </Typography>
            </div>
            <Typography variant="h5" className="user-popover__job-position">
              {props.user?.jobPosition?.name}
            </Typography>
          </Box>
          <UserLinkButton userId={props.user?.userId} />
        </Box>
      </Box>
      <Button variant="contained" onClick={handleButtonClick}>
        {t('userProfile.write')}
      </Button>
    </StyledUserPopoverContent>
  );
};

export default UserPopoverContent;
