import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMatch } from 'react-router-dom';
import HrmSidebarItem from './components/HrmSidebarItem/HrmSidebarItem';
import HrmSidebarStyle from './HrmSidebar.styles';
import ResizeableSidebar from 'src/ui/components/ResizeableSidebar/ResizeableSidebar';

import { ROUTES } from 'src/utils/constants';
import { useAppDispatch, useAppSelector } from 'src/store/store';
// import { ReactComponent as events } from './assets/events.svg';
// import { ReactComponent as calendar } from './assets/calendar.svg';
// import { ReactComponent as dashboard } from './assets/dashboard.svg';
// import { ReactComponent as extraHours } from './assets/extraHours.svg';
// import { ReactComponent as requests } from './assets/requests.svg';
import { ReactComponent as employees } from './assets/employees.svg';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import storage from 'src/utils/storage';

const HrmSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMatch = useMatch(ROUTES.hrm.path);
  const sidebarRef = React.useRef<HTMLDivElement | null>(null);
  const { user, widthHrmSidebar, isCloseHrmSidebar } = useAppSelector(({ main }) => main);
  const { t } = useTranslation(['general', 'hrmEmployees']);
  const usersWorkspaces = storage.selectedWorkspaces.get();

  if (!isMatch || !user || !usersWorkspaces || isCloseHrmSidebar) {
    return null;
  }

  // According to the business requirements of the alpha version [https://app.clickup.com/t/862kcvc20],
  // it is necessary to hide all inactive HRM menu items.
  // Do not remove commented out lines!
  //
  // TODO: Uncomment individual menu items as needed

  const menuItems = [
    // {
    //   path: ROUTES.home.path,
    //   label: t('hrmEmployees:modules.dashboard'),
    //   icon: dashboard,
    // },
    {
      path: ROUTES.employees.path,
      label: t('hrmEmployees:modules.employees'),
      icon: employees,
    },
    // {
    //   path: ROUTES.eventsCalendar.path,
    //   label: t('hrmEmployees:modules.calendar'),
    //   icon: calendar,
    // },
    // {
    //   path: ROUTES.events.path,
    //   label: t('hrmEmployees:modules.events'),
    //   icon: events,
    // },
    // {
    //   path: ROUTES.requestsList.path,
    //   label: t('hrmEmployees:modules.requests'),
    //   icon: requests,
    // },
    // {
    //   path: ROUTES.extraHours.path,
    //   label: t('hrmEmployees:modules.extra-hours'),
    //   icon: extraHours,
    // },
  ];

  const onChangeWidth = (width: number) => {
    dispatch(mainSliceActions.setWidthHrmSidebar(width));
  };

  const onCloseSidebar = (width: number) => {
    if (width < 144) {
      dispatch(mainSliceActions.setIsCloseHrmSidebar(true));
    }
  };

  return (
    <HrmSidebarStyle
      sidebarWidth={widthHrmSidebar}
    >
      <ResizeableSidebar
        sidebarRef={sidebarRef}
        onChangeWidth={onChangeWidth}
        onCloseSidebar={onCloseSidebar}
        border="right"
      >
        <div ref={sidebarRef}>
          <h1 className="module-title">
            {t('general:pages.hrm.pageTitle')}
          </h1>

          {/* <div className="link-container">
            <Link
              to={ROUTES.createRequest.path}
            >
              <p className="button-title">
                {t('general:sidebar.createRequest')}
              </p>
            </Link>
          </div> */}

          <div className="menu-container">
            {menuItems.map((item, index) => (
              <HrmSidebarItem
                key={index}
                item={item}
              />
            ))}
          </div>
        </div>
      </ResizeableSidebar>
    </HrmSidebarStyle>
  );
};

export default HrmSidebar;
