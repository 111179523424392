import type { TFunction } from 'react-i18next';
import type { IUser } from './userTypes';

export enum ChatTypesENUM {
  channel = 'channel',
  group = 'group',
  direct = 'direct',
}

export enum MessageTypeENUM {
  message = 'message',
  action = 'action',
}

export enum SearchResultsCategoryENUM {
  members = 'members',
  channels = 'channels',
  messages = 'messages',
}

export type MessageStatusType = 'sending' | 'sended' | 'readed';

export type MediaLocalType = {
  mediaInfoId: string;
  file: File;
  totalSize: number;
  uploaded: number;
  isError: boolean;
  isUploaded: boolean;
  mediaItem?: IMedia;
};

export type MediaInfoType = {
  [mediaInfoId: string]: MediaLocalType;
};

export type ChatMediaObjectType = {
  [channelId: string]: MediaInfoType | null;
};

export interface IMedia {
  mediaItemId: number;
  mimeType: string;
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date | null;
  name: string;
  isPrivate: boolean;
  key: string;
  thumbnailKey: string;
  tinyThumbnailKey: string;
  userUploaded?: IUser;
  messages?: IMessage[];
  userAvatar?: IUser;
  messageId?: number;
  link?: string;
  thumbLink?: string;
  tinyThumbLink?: string;
}

export interface IMessage {
  messageId: number;
  isAnswer: boolean;
  createdAt: string | Date;
  updatedAt?: string | Date;
  deletedAt?: string | Date;
  isEdited: boolean;
  userEditedAt?: string | Date;
  author: IUser;
  authorId?: number;
  channel?: IChannel;
  channelId?: number;
  messageReferences?: IMessage[];
  referencedMessage?: IMessage;
  childMessages?: IMessage[];
  parentMessage?: IMessage;
  messageText?: IMessageText[];
  messageToReactions?: IReaction[];
  hasBeenRead: boolean;
  type?: MessageTypeENUM;
  isSending?: boolean;
  isError?: boolean;
  isPinned?: boolean;
  isViewed?: boolean;
  media?: IMedia[];
  reactions?: IReaction[];
}

export interface IMessageText {
  messageTextId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  text: string;
  deletedAt?: string | Date;
  message?: IMessage;
}

export type UserToChannelsType = {
  userChannelId: number;
  userId?: number;
  user?: IUser;
  createdAt?: string | Date;
  lastViewedMessageTime?: string | Date;
  channel?: ChannelType;
};

export interface IChannel {
  channelId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date | null;
  isPrivate: boolean;
  isArchived: boolean;
  type: ChatTypesENUM;
  name: string;
  icon: string;
  creator?: IUser;
  owner?: IUser;
  ownerId?: number;
  companyId?: number;
  messages?: IMessage[];
  files?: IMedia[];
  userToChannels?: UserToChannelsType[];
  unreadMessagesCount: number;
  mentionsCount: number;
  description: string;
}

export interface IChannelDelta {
  channelId: number;
  ownerId: number;
  owner?: IUser;
}

export type ChannelType = {
  channelId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date | null;
  isPrivate: boolean;
  isArchived: boolean;
  type: ChatTypesENUM;
  name: string;
  icon: string;
  creator?: IUser;
  owner?: IUser;
  messagesIds?: number[];
  sendingMessagesIds?: number[];
  files?: IMedia[];
  userToChannels?: UserToChannelsType[];
  unreadMessagesCount: number;
  mentionsCount: number;
  description: string;
  pinnedMessages?: IMessage[];
  ownerId?: number;
};

export type MessageType = {
  messageId: number;
  isAnswer: boolean;
  createdAt: string | Date;
  updatedAt?: string | Date;
  deletedAt?: string | Date;
  isEdited: boolean;
  author: IUser;
  authorId?: number;
  channel?: IChannel;
  userEditedAt?: string | Date;
  messageReferences?: IMessage[];
  referencedMessage?: IMessage;
  childMessagesIds?: number[];
  parentMessage?: IMessage;
  type?: MessageTypeENUM;
  messageText?: IMessageText[];
  media?: IMedia[];
  mediaLocal?: MediaLocalType[];
  messageToReactions?: IReaction[];
  hasBeenRead: boolean;
  hasDiscussion?: boolean;
  isViewed?: boolean;
  isSending?: boolean;
  isError?: boolean;
  isPinned?: boolean;
  reactions?: IReaction[];
};

export interface IDiscussion {
  messagesIds?: number[];
  files?: File[];
}

export enum MessageActionModeENUM {
  editing = 'editing',
  replying = 'replying',
  pinned = 'pinned',
  normal = 'normal',
}

export interface IUserChannel {
  direct: IChannel[];
  group: IChannel[];
  channel: IChannel[];
}

export interface IEmoji {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string;
  emoticons?: string[];
}

export interface IReaction {
  messageReactionId: number;
  reaction: {
    emoji: string;
  };
  user: IUser;
  createdAt: string | Date;
}

export type DraftDataType = {
  channels: Record<string, string>;
  threads: Record<string, string>;
};

export type UnsentMessagesType = {
  [channelId: number]: Record<number, MessageType>;
};

export type DeleteMediaItemArgsType = {
  messageId: number;
  mediaItemId: number;
  t: TFunction<('chat' | 'errors')[], undefined>;
  mode?: MessageActionModeENUM;
};

export type TypingUsersType = {
  [id: number]: IUser & {
    timestamp: number;
  };
};

export type TooltipButtonType = {
  id: number;
  class: string;
  tooltip: string;
  isDisabledWhenCodeBlockIsActive?: boolean;
  value?: string;
  withSeparator?: boolean;
};

export enum MediaItemMimeTypeENUM {
  pdf = 'pdf',
  video = 'video',
  audio = 'audio',
  archive = 'zip/rar',
  image = 'image',
  word = 'msword/wordprocessingml',
}

export type ChannelMetaDataType = {
  channelId: number;
  unreadMessagesCount: number;
  isContainsMention: boolean;
};
