import styled from 'styled-components';

const LOADER_SIZE = 40;

export default styled.div <{ isFixed: boolean; blockPoinerEvents: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  inset: 0;
  z-index: 300;
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
  pointer-events: ${({ blockPoinerEvents }) => (blockPoinerEvents ? 'unset' : 'none')};

  .loader__icon {
    height: ${LOADER_SIZE}px;
    width: ${LOADER_SIZE}px;
  }

  .loader__title {
    z-index: 300;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
