const PER_PAGE = 30;
const SIDE_MESSAGES_COUNT = 15;
const RESERVE_COUNT = 3;
const MAX_FILE_SIZE = 1024 * 1024 * 1024;
const SUPPORTED_IMAGE_TYPE = ['jpeg', 'png', 'webp', 'gif'];

export default {
  PER_PAGE,
  SIDE_MESSAGES_COUNT,
  RESERVE_COUNT,
  MAX_FILE_SIZE,
  SUPPORTED_IMAGE_TYPE,
};
