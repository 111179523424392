import { v4 } from 'uuid';
import storage from '.';

const getDeviceId = () => {
  const deviceId = storage.deviceId.get();

  if (!deviceId) {
    const id = v4();
    storage.deviceId.set(id);
    return id;
  }

  return deviceId;
};

export default getDeviceId;
