import React from 'react';

import { useAppSelector } from 'src/store/store';
import type { IUser } from 'src/types';

export const useUser = () => {
  const user = useAppSelector(({ main }) => main.user);

  return user as IUser;
};

export const useWorkspacesList = () => {
  const availableWorkspaces = useAppSelector(({ main }) => main.availableWorkspaces);
  const checkedWorkspacesIds = useAppSelector(({ main }) => main.selectedWorkspaces);

  return {
    availableWorkspaces,
    checkedWorkspacesIds,
  };
};

export const useUserOnlineStatus = (userId: number) => {
  const isOnline = useAppSelector(({ main }) => Boolean(main.onlineUsers[userId]));

  return isOnline;
};

export const useOnlineCheck = () => {
  const onlineUsers = useAppSelector(({ main }) => main.onlineUsers);

  const checkOnlineStatus = React.useCallback((userId: number) => {
    return Boolean(onlineUsers[userId]);
  }, [onlineUsers]);

  return checkOnlineStatus;
};
