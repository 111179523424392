import styled from 'styled-components';
import Popover from '@mui/material/Popover';

export default styled(Popover)`
  .menu-container {
    display: flex;
    flex-direction: row;
  }
  .MuiPopover-paper {
    border-radius: 16px;
    box-shadow: none;

    /* TODO - Find a better solution later */
    margin-left: 44px;
    top: unset !important;
    bottom: 20px !important;
  }
  .MuiBackdrop-root {
    backdrop-filter: blur(2px) opacity(1);
    background: rgba(0, 0, 0, 0.35);
  }
`;
