import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import MenuItem from './MenuItem';
import WorkspaceItem from './WorkspaceItem';
import UserMenuAreaStyle from './UserMenuArea.styles';

import settingsIcon from '../assets/settings.svg';
import workersIcon from '../assets/workers.svg';
import logoutIcon from '../assets/logout.svg';
import { useAppDispatch, useAppSelector } from 'src/store';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import type { IUser } from 'src/types';
import storage from 'src/utils/storage';
import helpers from 'src/utils/helpers';
import { checkIsAxiosError, checkIsValidationError } from 'src/utils/handleValidationError';
import { ROUTES } from 'src/utils/constants';
import { t } from 'src/utils/getTranslation';
import { useWorkspacesList } from 'src/utils/hooks/general';
import authApi from 'src/api/authApi';

const UserMenuArea: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}> = (props) => {
  const user = useAppSelector(({ main }) => main.user) as IUser;
  const dispatch = useAppDispatch();
  const { availableWorkspaces, checkedWorkspacesIds } = useWorkspacesList();

  const currentDomain = helpers.getSubdomain();

  const currentWorkspace = Object.values(availableWorkspaces).find(
    (workspace) => workspace.subdomen === currentDomain,
  );

  if (!currentWorkspace) {
    return null;
  }

  const onLogoutWorkspace = async () => {
    try {
      const updatedSelectedWorkspaces = checkedWorkspacesIds.filter((id) => id !== currentWorkspace.companyId);
      dispatch(mainSliceActions.setSelectedWorkspaces(updatedSelectedWorkspaces));
      const newWorkspaceId = checkedWorkspacesIds.find((companyId) => {
        return companyId !== currentWorkspace.companyId;
      });
      if (!newWorkspaceId) {
        return;
      }

      const response = await authApi.changeCompany(newWorkspaceId);

      storage.authToken.set(response.data.meta.authToken);
      storage.refreshToken.set(response.data.meta.refreshToken);

      helpers.replaceSubdomain(response.data.payload.company!.subdomen);
    } catch (err) {
      if (checkIsAxiosError(err) && checkIsValidationError(err)) {
        toast.error(t('errors:server.validation.failed'));
      }
      console.error('src/ui/container/MainSidebar/components/UserMenuArea.tsx', err);
    }
  };

  return (
    <UserMenuAreaStyle>
      <div className="area-title">
        <WorkspaceItem
          workspaceItem={{
            companyId: currentWorkspace.companyId,
            subdomen: currentWorkspace.subdomen,
          }}
          setAnchor={props.setAnchor}
        />
        <div>
          <div className="company-title">
            {user?.company?.name}
          </div>
          <div className="domain-title">
            {`${user?.company?.subdomen}${t('authPages:signUp.workSpaceInput.domain.adornment')}`}
          </div>
        </div>
      </div>

      {user.isSuperAdmin && (
        <>
          <Link
            to={ROUTES.workspaceSettings.path}
            className="menu-item-link"
          >
            <MenuItem
              setAnchor={props.setAnchor}
              menuPunktIcon={settingsIcon}
              menuPunktTitle={t('general:userMenu.settingArea')}
            />
          </Link>

          <div className="workers">
            <Link
              to={ROUTES.employees.path}
            >
              <MenuItem
                setAnchor={props.setAnchor}
                menuPunktIcon={workersIcon}
                menuPunktTitle={t('general:userMenu.workers')}
              />
            </Link>
            <p className="invite">{t('general:userMenu.invite')}</p>
          </div>
        </>
      )}

      {checkedWorkspacesIds.length > 1 && (
        <MenuItem
          onClick={onLogoutWorkspace}
          setAnchor={props.setAnchor}
          menuPunktIcon={logoutIcon}
          menuPunktTitle={t('general:userMenu.logoutArea')}
        />
      )}
    </UserMenuAreaStyle >
  );
};

export default UserMenuArea;
