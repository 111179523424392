import Popover, { popoverClasses } from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import type { PopoverProps } from '@mui/material/Popover';

const UserPopover = styled(({ ...props }: PopoverProps) => (
  <Popover { ...props } />
))(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: `0px 2px 10px ${theme.palette.action.hover}`,
    minWidth: 'fit-content',
    padding: '10px 15px 15px',
    pointerEvents: 'auto',
  },
  [`& .${popoverClasses.root}`]: {
    pointerEvents: 'none',
  },
}));

export default UserPopover;
