import React from 'react';
import { useTranslation } from 'react-i18next';

import ModuleLink from './ModuleLink';
import ModuleLinksStyle from './ModuleLinks.styles';

import { ROUTES } from 'src/utils/constants';
import hrm from '../assets/hrm.svg';
import hrmIsActive from '../assets/hrmIsActive.svg';
import messenger from '../assets/messenger.svg';
import messengerIsActive from '../assets/messengerIsActive.svg';

const ModuleLinks: React.FC = () => {
  const { t } = useTranslation('general');

  return (
    <ModuleLinksStyle>
      <ModuleLink
        to={ROUTES.employees.path}
        routeToMatch={ROUTES.hrm.path}
        routeKey="hrm"
        icon={hrm}
        iconIsActive={hrmIsActive}
        toolTip={t('sidebar.hrm')}
      />

      <ModuleLink
        to={ROUTES.chat.createPath('default')}
        routeToMatch={ROUTES.chat.path}
        routeKey="chat"
        icon={messenger}
        iconIsActive={messengerIsActive}
        toolTip={t('sidebar.chat')}
      />
    </ModuleLinksStyle >
  );
};

export default ModuleLinks;
