import styled from 'styled-components';

export default styled.div`
  padding: 10px 20px;
  border-top: 1px solid ${({ theme }) => theme.palette.white.A1};;
  background-color: ${({ theme }) => theme.palette.background.footerMenu};  
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .item-title {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.palette.text.quaternary};
  };
  .apps-icons {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`;
