import io from 'socket.io-client';
import type { Socket } from 'socket.io-client';

import config from 'src/config';
import storage from 'src/utils/storage';
import socketConstants, { TOKEN_EXPIRED_ERROR } from './constants';
import refreshToken from '../http/refreshToken';
import newSocket from '../ws/socket';

/** Just a workaround */
let socket = null as unknown as Socket;
let companySocket = null as unknown as Socket;

type ErrorDataType = {
  message: string;
  payload: unknown[];
};

const socketDisconnect = () => {
  socket?.disconnect();
  companySocket?.disconnect();
};

const socketConnect = () => {
  socket?.connect();
  companySocket?.connect();
};

const makeRefreshToken = async () => {
  if (!socket || !companySocket) {
    return;
  }

  const tokenData = await refreshToken();
  socket.auth = { auth_token: tokenData.authToken };
  socket.disconnect().connect();
  companySocket.auth = { auth_token: tokenData.authToken };
  companySocket.disconnect().connect();
};

const getSocketInstance = (): Socket => {
  return newSocket.socket;

  if (socket) {
    return socket;
  }

  socket = io(config.socketUrl, {
    auth: { auth_token: storage.authToken.get() },
    transports: ['websocket'],
  });

  socket.on('connect_error', async (error) => {
    if (error.message === TOKEN_EXPIRED_ERROR && socket) {
      await makeRefreshToken();
    }
  });

  socket.on(socketConstants.SEND_ERROR, async (error: ErrorDataType) => {
    if (error.message === TOKEN_EXPIRED_ERROR && socket) {
      await makeRefreshToken();
      socket.emit(error.payload[0] as string, error.payload[1]);
    }
  });

  return socket;
};

const getCompanySocketInstance = (): Socket => {
  return newSocket.socket;

  if (companySocket) {
    return companySocket;
  }
  companySocket = io(`${config.socketUrl}/company`, {
    auth: { auth_token: storage.authToken.get() },
    transports: ['websocket'],
  });

  companySocket.on('connect_error', async (error) => {
    if (error.message === TOKEN_EXPIRED_ERROR && socket) {
      await makeRefreshToken();
    }
  });

  companySocket.on(socketConstants.SEND_ERROR, async (error: ErrorDataType) => {
    if (error.message === TOKEN_EXPIRED_ERROR && socket) {
      await makeRefreshToken();
      socket.emit(error.payload[0] as string, error.payload[1]);
    }
  });

  return companySocket;
};

export default {
  getSocketInstance,
  getCompanySocketInstance,
  socketDisconnect,
  socketConnect,
};
