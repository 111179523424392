import type { LanguageENUM, ThemeENUM, DraftDataType } from 'src/types';
import LocalStorageItem from './LocalStorageItem';
import CookieItem from './CookieItem';
import type { RouteKeyType } from '../constants';
import type { UnsentMessagesType } from 'src/types/chatTypes';
import config from 'src/config';

const cookieDomainValue = `.${config.cookieDomain}`;

export { LocalStorageItem, CookieItem };

type CreateWorkspaceType = {
  email: string;
  workspace: string;
  domain: string;
  startTime?: number;
  fullName?: string;
  phone?: string;
  phoneConfirmed?: boolean;
  agree?: boolean;
};

export default {
  theme: new LocalStorageItem<ThemeENUM>({ key: 'theme' }),
  language: new LocalStorageItem<LanguageENUM>({ key: 'language' }),
  authToken: new CookieItem<string>({ key: 'authToken', defaultOptions: { domain: cookieDomainValue } }),
  refreshToken: new CookieItem<string>({ key: 'refreshToken', defaultOptions: { domain: cookieDomainValue } }),
  selectedWorkspaces: new CookieItem<number[]>({ key: 'checkedAccounts', defaultValue: [], defaultOptions: { domain: cookieDomainValue } }),
  favoritesLinks: new LocalStorageItem<RouteKeyType[]>({ key: 'favoritesLinks', defaultValue: [] }),
  sidebarWidth: new LocalStorageItem<number>({ key: 'sidebarWidth', defaultValue: 215 }),
  threadWidth: new LocalStorageItem<number>({ key: 'threadWidth', defaultValue: 310 }),
  hrmSidebarWidth: new LocalStorageItem<number>({ key: 'sidebarWidth', defaultValue: 229 }),
  chatDrafts: new LocalStorageItem<DraftDataType>({ key: 'chatDrafts', defaultValue: { channels: {}, threads: {} } }),
  deviceId: new CookieItem<string>({ key: 'deviceId', defaultOptions: { domain: cookieDomainValue } }),
  unsentMessages: new LocalStorageItem<UnsentMessagesType>({ key: 'unsentMessages', defaultValue: {} }),
  workspaceIsCreating: new LocalStorageItem<CreateWorkspaceType | null>({ key: 'workspaceIsCreating' }),
};
