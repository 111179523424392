import React from 'react';

const useElementWidth = (ref: React.MutableRefObject<HTMLDivElement | HTMLSpanElement | null>) => {
  const [width, setWidth] = React.useState<null | number>(null);

  const observer = React.useRef(
    new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setWidth(width);
    }),
  );

  React.useEffect(() => {
    if (ref.current) {
      observer.current.observe(ref.current);
    }
  },
  [ref, observer]);

  return Math.round(width || 0);
};

export default useElementWidth;
