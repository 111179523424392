/* eslint-disable class-methods-use-this */
import i18next from 'i18next';

import type { MessageType } from 'src/types';

class BrowserNotifications {
  private notificationPermission: NotificationPermission | null = null;

  private showNotification = (title: string, options?: NotificationOptions) => {
    if (this.isAppActive()) {
      return;
    }

    return new Notification(title, options);
  };

  private isAppActive = () => {
    return !document.hidden;
  };

  requestPermissions = async () => {
    if (!this.notificationPermission || this.notificationPermission === 'default') {
      this.notificationPermission = await Notification.requestPermission();
    }
  };

  showMessageNotification = (message: MessageType) => {
    return this.showNotification(`${i18next.t('chat:pushNotifications.messageFrom')} ${message.author.fullName}`);
  };
}

export default new BrowserNotifications();
