export type ResponseType<P = null, M extends object = Record<string, never>> = {
  message: string;
  payload: P;
  meta: M;
};

export enum ErrorTypeENUM {
  validation = 'validation',
  notAcceptable = 'notAcceptable',
  authorization = 'authorization',
  access = 'access',
  notFound = 'notFound',
  internal = 'internal',
  requestTimeout = 'requestTimeout',
}

export type ErrorResponseType<D = null> = {
  type: ErrorTypeENUM;
  statusCode: number;
  code: string;
  data: D;
};

export type GetParamsType = {
  sortDirection?: string;
  sortBy?: string;
  page?: number;
  perPage?: number;
  search?: string;
  // TODO: remove type string for isDeleted
  isDeleted?: string | boolean;
};
