import React from 'react';

import { Link, useMatch } from 'react-router-dom';
import HrmSidebarItemStyle from './HrmSidebarItem.styles';
import useChangeTitleName from 'src/utils/hooks/useChangeTitleName';

const HrmSidebarItem: React.FC<{
  item: {
    path: string;
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  };
}> = ({ item }) => {
  const isActive = useMatch(item.path);
  useChangeTitleName(item.label);

  return (
    <HrmSidebarItemStyle
      isActive={isActive}
    >
      <Link to={item.path}>
        <div className="link-title">
          <item.icon className="image" />

          <p className="label">
            {item.label}
          </p>
        </div>
      </Link>
    </HrmSidebarItemStyle >
  );
};

export default HrmSidebarItem;
