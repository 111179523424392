import styled from 'styled-components';

import hrmSidebarIcon from './assets/hrmSidebarIcon.svg';
import hrmSidebarIconActive from './assets/hrmSidebarIconActive.svg';

export default styled.aside`
  display: flex;
  flex-direction: column;
  padding: 9px 9px 20px 9px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};;
  border-right: 1px solid ${({ theme }) => theme.palette.white.A1};
  .left-sidebar__links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .left-sidebar__logo-image {
    vertical-align: middle;
    margin-bottom: 40px;
    cursor: pointer;
  }
  .left-sidebar__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
  }
  .hrm-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url(${hrmSidebarIcon});
  }
  .hrm-button:hover {
    background-image: url(${hrmSidebarIconActive});
  }
`;
