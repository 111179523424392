import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/store';

const useChangeTitleName = (title?: string | null) => {
  const companyName = useAppSelector(({ main }) => main.user?.company?.name || '');
  const { t } = useTranslation('general');
  const defaultTitle = t('appName');

  React.useEffect(() => {
    if (!title || !companyName) {
      document.title = defaultTitle;
      return;
    }

    const newTitle = `${title} | ${companyName}`;
    document.title = newTitle;
  }, [title, companyName, defaultTitle]);

  React.useEffect(() => {
    return () => {
      document.title = defaultTitle;
    };
  }, [defaultTitle]);
};

export default useChangeTitleName;
