import React from 'react';

import StyleBadge from './StatusBadge.style'
import { BadgeProps } from '@mui/material/Badge';

type StatusBadgeProp = {
  isOnline: boolean;
  customColor?: {
    success: string;
    secondary: string;
  };
  size?: 'xs' | 'sm';
  withStroke?: boolean;
}

const StatusBadge: React.FC<StatusBadgeProp & BadgeProps> = (props) => {
  const statusColor = React.useMemo(() => {
    return props.isOnline ? 'success' : 'secondary';
  }, []);

  return (
    <StyleBadge
      {...props}
      overlap={props.overlap || 'circular'}
      variant={props.variant || 'dot'}
      color={!props.customColor ? statusColor : undefined}
      isOnline={props.isOnline}
      customColor={props.customColor}
      size={props.size || 'xs'}
      withStroke={props.withStroke}
    >
      {props.children}
    </StyleBadge>
  )
}

export default StatusBadge;