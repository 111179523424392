import styled from 'styled-components';

export default styled.div`

.workspace-container & .active-workspace {
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.palette.tabs.selected};
}
`;
