import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import AvatarBlock from './components/AvatarBlock';
import ModuleLinks from './components/ModuleLinks';
import MainSidebarStyle from './MainSidebar.styles';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import sphere from './assets/sphere.svg';
import { ROUTES } from 'src/utils/constants';
import { useWorkspacesList } from 'src/utils/hooks/general';
import { t } from 'src/utils/getTranslation';

const MainSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { checkedWorkspacesIds } = useWorkspacesList();
  const navigate = useNavigate();
  const isHrmMatch = useMatch(ROUTES.hrm.path);
  const isChatMatch = useMatch(ROUTES.chatGeneral.path);
  const isWorkspaceMatch = useMatch(ROUTES.workspace.path);
  const {
    user,
    widthHrmSidebar,
    isCloseHrmSidebar,
    isCloseChatSidebar,
  } = useAppSelector(({ main }) => main);

  React.useEffect(() => {
    if (widthHrmSidebar > 144) {
      dispatch(mainSliceActions.setIsCloseHrmSidebar(false));
    }
  }, [dispatch, widthHrmSidebar]);

  if (!user || !checkedWorkspacesIds.length || isWorkspaceMatch) {
    return null;
  }

  const onHrmSidebarShow = () => {
    dispatch(mainSliceActions.setWidthHrmSidebar(145));
    dispatch(mainSliceActions.setIsCloseHrmSidebar(false));
  };

  const onChatSidebarShow = () => {
    dispatch(mainSliceActions.setIsCloseChatSidebar(false));
  };

  const onLogoClick = () => {
    navigate(ROUTES.employees.path);
  };

  return (
    <MainSidebarStyle>
      <nav className="left-sidebar__links-container">
        <div
          className="left-sidebar__logo-image"
          onClick={onLogoClick}
        >
          <img src={sphere} alt="logo sphere" />
        </div>

        <ModuleLinks />
      </nav>

      <div className="left-sidebar__button-container">
        {(isCloseHrmSidebar && isHrmMatch) && (
          <Tooltip placement="top-end" arrow title={t('general:sidebar.sidebarButton')}>
            <div
              onClick={onHrmSidebarShow}
              className="hrm-button"
            />
          </Tooltip>
        )}

        {(isCloseChatSidebar && isChatMatch) && (
          <Tooltip placement="top-end" arrow title={t('general:sidebar.sidebarButton')}>
            <div
              onClick={onChatSidebarShow}
              className="hrm-button"
            />
          </Tooltip>
        )}

        <AvatarBlock />
      </div>
    </MainSidebarStyle>
  );
};

export default MainSidebar;
