import styled from 'styled-components';

type WidthPropType = {
  sidebarWidth?: number;
};

export default styled.aside<WidthPropType>`
  max-width: ${(props) => props.sidebarWidth}px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};;
  display: flex;
  flex-direction: column;

  .module-title {
    border-bottom: 1px solid ${({ theme }) => theme.palette.white.A1};
    white-space: nowrap;
    font-weight: ${({ theme }) => theme.font.weight.lg};
    font-size: ${({ theme }) => theme.font.size.lg};
    line-height: 24px;
    padding: 16px 18px;
    color: ${({ theme }) => theme.palette.text.tertiary};   
    overflow: hidden; 
    text-overflow: ellipsis; 
  }

  .link-container {
    padding: 10px 10px;
  }

  .button-title {
    max-width: 191px;
    font-size: 16px;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary[500]};
    color: ${({ theme }) => theme.palette.primary[500]};
    text-align: center;
    border-radius: 6px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
  }

  .menu-container {
    padding: 10px 0 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
`;
