import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  extraList: [],
  unpaidExtraHours: [],
  userExtraList: [],
  extraItemForEdit: null,
  page: 1,
  totalPages: null,
  sortBy: null,
  sortDirection: null,
  isPaid: null,
  selectedUsers: null,
  isPopover: false,
});

const extraSlice = createSlice({
  name: 'extra',
  initialState: getInitialStore(),
  reducers: {
    setExtraList: (store, { payload }) => ({
      ...store,
      extraList: payload,
    }),
    setUserExtraList: (store, { payload }) => ({
      ...store,
      userExtraList: payload,
    }),
    setUnpaidExtraHours: (store, { payload }) => ({
      ...store,
      unpaidExtraHours: payload,
    }),
    setSortBy: (store, { payload }) => ({
      ...store,
      sortBy: payload.sortBy,
    }),
    setSortDirection: (store, { payload }) => ({
      ...store,
      sortDirection: payload.sortDirection,
    }),
    setIsProcessed: (store, { payload }) => ({
      ...store,
      isPaid: payload.value,
    }),
    setSelectedUsers: (store, { payload }) => ({
      ...store,
      selectedUsers: payload.value,
    }),
    setPage: (store, { payload }) => ({
      ...store,
      page: payload.page,
    }),
    setTotalPages: (store, { payload }) => ({
      ...store,
      totalPages: payload,
    }),
    setExtraItemForEdit: (store, { payload }) => ({
      ...store,
      extraItemForEdit: payload,
    }),
    setIsPopover: (store, { payload }) => ({
      ...store,
      isPopover: payload,
    }),
  },
});

export const {
  setExtraList,
  setUserExtraList,
  setUnpaidExtraHours,
  updateUserExtraList,
  setSortBy,
  setSortDirection,
  setIsProcessed,
  setSelectedUsers,
  setPage,
  setTotalPages,
  setExtraItemForEdit,
  setIsPopover,
} = extraSlice.actions;

export default extraSlice.reducer;
