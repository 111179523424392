import { ReactComponent as CloseIcon } from 'src/ui/assets/images/close.svg';
import logoText, { ReactComponent as LogoText } from './logoText.svg';
import { ReactComponent as CheckboxIcon } from './checkbox.svg';
import { ReactComponent as CheckedCheckboxIcon } from './checkbox-check.svg';
import { ReactComponent as CheckedGreenIcon } from './tick-circle.svg';

export default {
  logoCircle: `${process.env.PUBLIC_URL}/logo-circle.svg`,
  logoText,
  LogoText,

  CloseIcon,
  CheckboxIcon,
  CheckedCheckboxIcon,
  CheckedGreenIcon,
};
