import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  inventory: null,
  deviceTypes: [],
  isLoading: false,
  toggleModalForm: false,
  users: [],
  total: null,
  pagination: {
    perPage: 100,
    page: 1,
  },
  sortBy: null,
  sortDirection: 'ASC',
  filter: {
    search: '',
    selectedUsers: [],
    selectedDeviceTypes: [],
  },
});

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: getInitialStore(),
  reducers: {
    setInventory: (store, { payload }) => ({
      ...store,
      inventory: payload,
    }),
    setDeviceTypes: (store, { payload }) => ({
      ...store,
      deviceTypes: payload,
    }),
    setUsers: (store, { payload }) => ({
      ...store,
      users: payload,
    }),
    setToggleModalForm: (store, { payload }) => ({
      ...store,
      toggleModalForm: payload,
    }),
    updateFilter: (store, { payload }) => {
      return {
        ...store,
        filter: {
          ...store.filter,
          [payload.name]: payload.value,
        },
      };
    },
    clearFilter: (store) => ({
      ...store,
      filter: getInitialStore().filter,
    }),
    updateSearch: (store, { payload }) => {
      return {
        ...store,
        filter: {
          ...store.filter,
          search: payload,
        },
      };
    },
    updateSort: (store, { payload }) => ({
      ...store,
      sortBy: payload.sortBy,
      sortDirection: payload.sortDirection,
    }),
    updatePagination: (store, { payload }) => {
      return {
        ...store,
        pagination: payload,
      };
    },
    setTotalPages: (store, { payload }) => {
      return {
        ...store,
        total: payload,
      };
    },
    setIsLoading: (store, { payload }) => ({
      ...store,
      isLoading: payload,
    }),
  },
});

export const {
  setInventory,
  setIsLoading,
  setDeviceTypes,
  setUsers,
  clearFilter,
  updateFilter,
  updateSearch,
  updatePagination,
  updateSort,
  setTotalPages,
  setToggleModalForm,
} = inventorySlice.actions;

export default inventorySlice.reducer;
