import styled from 'styled-components';

import Tooltip from '@mui/material/Tooltip';

export default styled(Tooltip)`
  .settings__alert-circle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
