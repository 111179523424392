import { isDev } from 'src/config';
import { SECOND, MINUTE } from 'src/utils/constants';

const MIN_GAP_BETWEEN_EVENTS = (isDev ? SECOND : MINUTE);
const MAX_GAP_BETWEEN_EVENTS = (isDev ? SECOND : MINUTE) * 5;

const createonEventFunction = (callback: () => Promise<void> | void) => {
  let lastUpdateTimestamp = 0;
  let timeoutId: NodeJS.Timeout;

  return () => {
    const currentActivityTimestamp = Date.now();

    if ((currentActivityTimestamp - lastUpdateTimestamp) < MAX_GAP_BETWEEN_EVENTS) {
      clearTimeout(timeoutId);
    }

    lastUpdateTimestamp = currentActivityTimestamp;
    timeoutId = setTimeout(() => {
      callback();
    }, MIN_GAP_BETWEEN_EVENTS);
  };
};

const addActivityHandler = (callback: () => Promise<void> | void) => {
  const onEvent = createonEventFunction(callback);

  const eventsList = [
    'wheel',
    'mousedown',
    'mousemove',
    'mouseup',
    'touchstart',
    'touchmove',
    'touchend',
    'keydown',
    'keyup',
    'resize',
  ];

  eventsList.forEach((eventName) => {
    window.addEventListener(eventName, onEvent);
  });

  return () => {
    eventsList.forEach((eventName) => {
      window.removeEventListener(eventName, onEvent);
    });
  };
};

export default addActivityHandler;
