import React from 'react';

import type { TooltipProps } from '@mui/material/Tooltip';
import StyledArrowTooltip from './ArrowTooltip.styles';

const ArrowTooltip: React.FC<TooltipProps> = (props) => {
  return (
    <StyledArrowTooltip
      arrow
      placement="top"
      {...props}
    >
      <span>{props.children}</span>

    </StyledArrowTooltip>
  );
};

export default ArrowTooltip;
