import { createApi } from '@reduxjs/toolkit/query/react';

import { type ResponseType } from 'src/api/http';
import baseQuery from './baseQuery';
import type { IDepartment } from 'src/types';

const path = 'departments';

export const departmentsQuery = createApi({
  reducerPath: 'departments',
  baseQuery: baseQuery<IDepartment[]>(),
  endpoints: (builder) => ({
    getDepartmentsList: builder.query<ResponseType<IDepartment[]>, void>({
      query: () => ({
        url: path,
        method: 'GET',
      }),
    }),
    createDepartment: builder.mutation<ResponseType<IDepartment>, { name: string }>({
      query: (data) => ({
        url: path,
        method: 'POST',
        data,
      }),
    }),
    updateDepartment:
      builder.mutation<ResponseType<IDepartment>, { name: string; departmentId: number }>({
        query: (data) => ({
          url: `${path}/${data.departmentId}`,
          method: 'PATCH',
          data: { name: data.name },
        }),
      }),
    deleteDepartment: builder.mutation<ResponseType, number>({
      query: (id) => ({
        url: `${path}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetDepartmentsListQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentsQuery;
