import React from 'react';

import type { IUser, UserPermissionsENUM } from 'src/types';
import { useAppSelector } from 'src/store';

type WithAuthPropsType = {
  isAuthRequired?: true;
  permissions?: UserPermissionsENUM[];
};

type NoAuthPropsType = {
  isAuthRequired: false;
};

export type AuthPropsType = WithAuthPropsType | NoAuthPropsType;
export type RouteDataType = {
  path: string;
  auth?: AuthPropsType;
};

type PropsType = AuthPropsType & {
  fallbackNode?: React.ReactNode;
};

const validateUserPermissions = (user: IUser, permissions: UserPermissionsENUM[]) => {
  const userPermissions = {} as Record<string, true>;
  user.permissions?.forEach(({ value }) => {
    userPermissions[value] = true;
  });

  for (let i = 0; i < permissions.length; i++) {
    const requiredPermission = permissions[i];
    if (!userPermissions[requiredPermission]) {
      return false;
    }
  }

  return true;
};

export const validateUserAuth = (user: IUser | null, auth: AuthPropsType) => {
  if (!user) {
    return !auth.isAuthRequired;
  }

  if (user.isSuperAdmin) {
    return true;
  }

  if (user && !auth.isAuthRequired) {
    return false;
  }

  if ((auth as WithAuthPropsType).permissions?.length) {
    return validateUserPermissions(user, (auth as WithAuthPropsType).permissions || []);
  }

  return true;
};

export const useIsAvailable = (props: AuthPropsType = {}) => {
  const user = useAppSelector(({ main }) => main.user);
  const requiredPermissions = React.useRef((props.isAuthRequired && props.permissions) || []);
  const isAuthRequired = React.useRef(props.isAuthRequired ?? true);

  const isAvailable = React.useMemo<boolean>(() => {
    return validateUserAuth(user, {
      isAuthRequired: isAuthRequired.current,
      permissions: requiredPermissions.current,
    } as unknown as WithAuthPropsType);
  }, [user]);

  return isAvailable;
};

const Protector: React.FC<React.PropsWithChildren & PropsType> = (props) => {
  const isAvailable = useIsAvailable(props);

  if (!isAvailable) {
    return props.fallbackNode as React.ReactElement || null;
  }

  return props.children as React.ReactElement;
};

export default Protector;
