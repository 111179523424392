import styled from 'styled-components';

export default styled.div`
  display: flex;   
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  width: fit-content;

  .item-title {
    margin: 0;
    padding: 0;
  }
`;
