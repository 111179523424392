import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 284px;
  height: auto;
  color: ${({ theme }) => theme.palette.secondary.light};
  background-color: ${({ theme }) => theme.palette.background.default};
  border-bottom: 1px solid ${({ theme }) => theme.palette.white.A1};
  padding: 12px 20px 20px 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  .user-title {
    color: ${({ theme }) => theme.palette.text.tertiary};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .user-name {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 18px;
  }

  .menu-item-link {
    width: fit-content;
  }
`;
