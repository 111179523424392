import type { ICompany } from './generalTypes';

export enum UserPermissionsENUM {
  userGetList = 'usersGetList',
  userEdit = 'usersEdit',
  userEditPermissions = 'usersEditPermissions',
  userSoftDelete = 'usersSoftDelete',
  userHardDelete = 'usersHardDelete',
  manageRoles = 'manageRoles',
}

export interface IUserPermission {
  userPermissionId: number;
  name: string;
  value: UserPermissionsENUM;
  users?: IUser[];
  userRoles?: IUserRole[];
}

export interface IUserRole {
  userRoleId: number;
  name: string;
  isSuperAdmin: boolean;
  isDefault: boolean;
  users?: IUser[];
  permissions?: IUserPermission[];
}

export interface IJobPosition {
  name: string;
  jobPositionId: number;
  createdAt?: string | Date;
}

export enum UserStatusENUM {
  registered = 'registered',
  active = 'active',
  disabled = 'disabled',
}

export enum SortingENUM {
  byName = 'fullName',
  byCeatedAt = 'createdAt',
  byPosition = 'jobPosition',
  byStatus = 'status',
}

export interface IPagesInfo {
  page: number;
  perPage: number;
  totalPages: number;
  totalRecords: number;
}

export interface IMediaItem {
  mediaItemId: number;
  isPrivate: boolean;
  key: string;
  thumbnailKey: string;
  thumbLink: string;
  tinyThumbnailKey: string;
  tinyThumbLink: string;
  link: string;
}

export interface IUser {
  userId: number;
  createdAt?: string | Date;
  updatedAt?: string | Date;
  deletedAt?: string | Date | null;
  lastActivity?: string | Date | null;
  workingFrom?: string;
  status: UserStatusENUM;
  isSuperAdmin: boolean;
  jobPositionId?: number;
  email: string;
  phone?: string;
  firstName: string | null;
  lastName: string | null;
  patronymic?: string | null;
  dateOfBirth?: string;
  description?: string;
  fullName?: string | null;
  roles?: IUserRole[];
  permissions?: IUserPermission[];
  avatarMediaItem?: IMediaItem;
  company?: ICompany;
  jobPosition?: IJobPosition;
}

export interface IWorkspace {
  companyId: number;
  subdomen: string;
}

export interface IProfile extends IUser {
  description?: string;
  sex?: string;
  inn?: string;
  snils?: string;
  statusInCompany?: string;
  typeOfContract?: string;
  typeOfWorkingDay?: string;
  familyStatus: string;
  registrationAddress: string;
  residentialAddress: string;
  additionalContacts?: IAdditionalContact[];
  children?: IChildren[];
  department?: IDepartment;
  education?: IEducation[];
  manager?: IManager;
}

export interface IAdditionalContact {
  id: number;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  phoneNumner: string;
  typeOfRelative: string;
}

export interface IChildren {
  id: number;
  name?: string;
  sex?: string;
  dateOfBirth?: string | null;
}

export interface IDepartment {
  departmentId: number;
  name: string;
}

export interface IEducation {
  id: number;
  institution: string;
  name: string;
  type: string;
}

export interface IManager {
  userId: number;
  firstName: string;
  lastName: string;
  patronymic?: string;
}

export type QueryParamsType = {
  page: string | number;
  perPage: string | number;
  sortBy: string;
  sortDirection: string;
  search: string;
  tab?: string;
  roles: string;
  statuses: string;
  view?: string;
};

export type OnSubmitEditUserDataType = {
  workingFrom?: string | Date | null;
  status?: UserStatusENUM;
  isSuperAdmin?: boolean;
  email?: string;
  phone?: string;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  info?: string | null;
  fullName?: string | null;
  role?: IUserRole;
  permissions?: IUserPermission[];
};

export type ConfirmationUserDataType = {
  userId: number;
  firstName: string;
  company: ICompany;
  email: string;
  phone: string;
};

export type UserContactsType = Pick<IUser, 'userId' | 'lastActivity' | 'workingFrom' | 'email' | 'phone' | 'firstName' | 'lastName'>;
