import _defaultsDeep from 'lodash/defaultsDeep';
import transparentize from 'polished/lib/color/transparentize';
import lighten from 'polished/lib/color/lighten';

import main from 'src/legacy/ui/styles/StyledComponentsTheme/themes/main';

const colors = {
  primaryGray: '#484848',
  redMain: '#f44336',
};

const pageBackground = '#3C3A3F';

export default _defaultsDeep(
  {
    colors: {
      ...colors,
      pageBackground,
      mainText: 'white',
      navbar: {
        background: '#363239',
        backgroundLight: '#363239',
        backgroundDark: 'black',
        text: '#A79DB1',
        textLight: 'white',
      },
      diagram: {
        headerBorder: '#bbbbbb20',
        text: 'ffffffc0',
      },
      table: {
        row: {
          background: transparentize(0.2, '#333135'),
          backgroundDark: transparentize(0.3, '#333135'),
          backgroundLight: lighten(0.1, pageBackground),
        },
        borderColor: {
          main: '#69686b',
        },
        clearFilterButton: {
          modifier: {
            disabledButtonText: '#69686b',
            disabledButtonBackground: '#c4c3c5',
          },
        },
      },
      requests: {
        status: {
          accept: {
            contrast: '#3C3A3F',
          },
          completed: {
            contrast: '#3C3A3F',
          },
          denied: {
            contrast: '#3C3A3F',
          },
          inProgress: {
            contrast: '#3C3A3F',
          },
          wait: {
            contrast: '#3C3A3F',
          },
        },
        row: {
          background: transparentize(0.2, '#333135'),
          backgroundDark: transparentize(0.3, '#333135'),
          backgroundLight: lighten(0.1, pageBackground),
        },
      },
      calendar: {
        background: '#black',
      },
    },
  },
  main,
);
