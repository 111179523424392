import type { SortDirectionENUM } from 'src/types';

export enum RequestStatusENUM {
  wait = 'wait',
  inProgress = 'inProgress',
  completed = 'completed',
  accept = 'accept',
  denied = 'denied',
  cancelled = 'cancelled',
}

export enum RequestTypeENUM {
  technical = 'technical',
  vacation = 'vacation',
  medical = 'medical',
  dayOff = 'dayOff',
  common = 'common',
  documents = 'documents',
}

export interface IRequest {
  title: string;
  description: string;
  dateFrom: string | Date;
  dateTo: string | Date;
  isUnpaid: boolean;
  willCompensateHours: boolean;
  type: RequestTypeENUM | null;
  userId?: number;
}

export interface IStatusRequest {
  status: RequestStatusENUM;
  rejectReason: string | null;
}

export interface IGetUserRequestsListParams {
  page: string;
  perPage: string;
  sortBy: 'user' | 'title' | 'createdAt' | 'type' | 'status';
  sortDirection: SortDirectionENUM;
}

export interface IGetAllRequestsListParams extends IGetUserRequestsListParams {
  search: string;
  userIds: string;
  types: string;
  statuses: string;
  dateFrom: string;
  dateTo: string;
}
