import React from 'react';
import { useTranslation } from 'react-i18next';

import UserMenuDownloadStyle from './UserMenuDownload.styles';

import androidIcon from '../assets/android.svg';
import iosIcon from '../assets/ios.svg';

const UserMenuDownload: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}> = (props) => {
  const { t } = useTranslation('general');

  return (
    <UserMenuDownloadStyle>
      <p className="item-title">
        {t('userMenu.download')}
      </p>
      <div className="apps-icons">
        <div
          onClick={() => props.setAnchor(null)}
          className="cursor-pointer"
        >
          <img src={androidIcon} alt={t('userMenu.android')} />
        </div>
        <div
          onClick={() => props.setAnchor(null)}
          className="cursor-pointer"
        >
          <img src={iosIcon} alt={t('userMenu.ios')} />
        </div>
      </div>
    </UserMenuDownloadStyle >
  );
};

export default UserMenuDownload;
