import styled, { css } from 'styled-components';

import type { PathMatch } from 'react-router-dom';

type PropsType = {
  isActive: PathMatch<string> | null;
};

export default styled.div<PropsType>`
  padding: 10px 16px;
  .link-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .label {
    font-weight: ${({ theme }) => theme.font.weight.md};
    font-size: ${({ theme }) => theme.font.size.sm};
    line-height: 18px;
    color: ${({ theme }) => theme.palette.text.secondary};
    padding-left: 8px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
  }
  ${(props) => {
    if (props.isActive) {
      return css`
        border-left: 2px solid ${({ theme }) => theme.palette.reactions.pressed.color};
        background-color: ${({ theme }) => theme.palette.reactions.pressed.background};
        font-weight: ${({ theme }) => theme.font.weight.lg};
        padding-left: 14px;
        .label {
          color: ${({ theme }) => theme.palette.reactions.pressed.color};
        }
        .image path:first-child {
          fill: ${({ theme }) => theme.palette.primary[300]};
        }
        .image path:not(:first-child) {
          fill: ${({ theme }) => theme.palette.primary[500]};
        }
    `;
    }
  }}
`;
