import { createSlice } from '@reduxjs/toolkit';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import { getCurrentQuery } from 'src/legacy/utils/hooks/useQueryString';
import { REQUEST_TYPE_OPTIONS, REQUEST_STATUS_OPTIONS } from '../utils/constants';

const queryData = getCurrentQuery();
const typesFromQuery = REQUEST_TYPE_OPTIONS.filter((option) => {
  return queryData.selectedTypes?.includes(option.value);
});

const statusesFromQuery = REQUEST_STATUS_OPTIONS.filter((option) => {
  return queryData.selectedStatuses?.includes(option.value);
});

const getInitialState = () => ({
  requestsList: [],
  totalPages: 1,
  page: Number(queryData.page) || 1,
  sortBy: queryData.sortBy || 'createdAt',
  sortDirection: queryData.sortDirection || 'DESC',
  users_idFilter: null,
  typesFilter: typesFromQuery.length ? typesFromQuery : null,
  statusesFilter: statusesFromQuery.length ? statusesFromQuery : null,
  dateFrom: queryData.dateFrom ? parse(queryData.dateFrom, 'dd.MM.yyyy', new Date()) : null,
  dateTo: queryData.dateTo ? parse(queryData.dateTo, 'dd.MM.yyyy', new Date()) : null,
  searchString: queryData?.search || '',
  requestsView: queryData?.tab || 'table',
  calendarDateFrom: queryData.calendarDateFrom ? startOfDay(parse(queryData.calendarDateFrom, 'dd.MM.yyyy', new Date())) : null,
  calendarDateTo: queryData.calendarDateTo ? endOfDay(parse(queryData.calendarDateTo, 'dd.MM.yyyy', new Date())) : null,
  requestFormModal: {
    isModalOpen: false,
    request: {},
    currentUser: null,
  },
});

const requestsSlice = createSlice({
  name: 'requestsPage',
  initialState: getInitialState(),
  reducers: {
    updateRequestsView: (state, { payload }) => ({
      ...state,
      requestsView: payload,
    }),
    updateRequestsList: (state, { payload }) => ({
      ...state,
      requestsList: payload,
    }),
    updateTotalPages: (state, { payload }) => ({
      ...state,
      totalPages: payload,
    }),
    updatePagination: (state, { payload }) => ({
      ...state,
      page: payload,
    }),
    updateSortBy: (state, { payload }) => ({
      ...state,
      sortBy: payload,
    }),
    updateSortDirection: (state, { payload }) => ({
      ...state,
      sortDirection: payload,
    }),
    updateStatusesFilter: (state, { payload }) => ({
      ...state,
      statusesFilter: payload,
    }),
    updateTypesFilter: (state, { payload }) => ({
      ...state,
      typesFilter: payload,
    }),
    updateUsers_idFilter: (state, { payload }) => ({
      ...state,
      users_idFilter: payload,
    }),
    updateSearchString: (state, { payload }) => ({
      ...state,
      searchString: payload,
    }),
    clearFilter: (state) => ({
      ...state,
      page: 1,
      users_idFilter: null,
      typesFilter: null,
      statusesFilter: null,
      dateFrom: null,
      dateTo: null,
      searchString: '',
    }),
    setRequestForModal: (state, { payload }) => ({
      ...state,
      requestFormModal: {
        isModalOpen: false,
        request: payload,
        currentUser: null,
      },
    }),
    openRequestFormModal: (state, { payload }) => ({
      ...state,
      requestFormModal: {
        isModalOpen: true,
        request: payload.request,
        currentUser: payload.currentUser,
      },
    }),
    closeRequestFormModal: (state) => ({
      ...state,
      requestFormModal: {
        isModalOpen: getInitialState().requestFormModal.isModalOpen,
        request: state.requestFormModal.request,
        currentUser: state.requestFormModal.currentUser,
      },
    }),
    updateDateFrom: (state, { payload }) => ({
      ...state,
      dateFrom: payload,
    }),
    updateDateTo: (state, { payload }) => ({
      ...state,
      dateTo: payload,
    }),
    updateCalendarDateFrom: (state, { payload }) => ({
      ...state,
      calendarDateFrom: payload,
    }),
    updateCalendarDateTo: (state, { payload }) => ({
      ...state,
      calendarDateTo: payload,
    }),
  },
});

export const {
  updateRequestsView,
  updateRequestsList,
  updateTotalPages,
  updatePagination,
  updateSort,
  updateSortBy,
  updateSortDirection,
  updateStatusesFilter,
  updateTypesFilter,
  updateUsers_idFilter,
  updateSearchString,
  clearFilter,
  openRequestFormModal,
  closeRequestFormModal,
  setRequestForModal,
  updateDateFrom,
  updateDateTo,
  updateCalendarDateFrom,
  updateCalendarDateTo,
} = requestsSlice.actions;

export default requestsSlice.reducer;
