import { ThemeENUM, LanguageENUM } from 'src/types';
import storage from 'src/utils/storage';

export const getTheme = (): ThemeENUM => {
  return ThemeENUM.light as ThemeENUM; // TODO: Replace when other theme apperar
  const theme = storage.theme.get() as ThemeENUM;
  if (theme && Object.values(ThemeENUM).includes(theme)) {
    return theme;
  }

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    storage.theme.set(ThemeENUM.dark);
    return ThemeENUM.dark;
  }

  storage.theme.set(ThemeENUM.light);
  return ThemeENUM.light;
};

export const getLanguage = (): LanguageENUM => {
  return LanguageENUM.ru; // TODO: Replace when other localization apperar
  const storedLanguage = storage.language.get() as LanguageENUM;

  if (storedLanguage) {
    return storedLanguage;
  }

  const systemLanguage = Intl
    .DateTimeFormat()
    .resolvedOptions().locale
    .substring(0, 2)
    .toLowerCase();

  if (systemLanguage === LanguageENUM.ru) {
    return LanguageENUM.ru;
  }
  return LanguageENUM.en;
};
